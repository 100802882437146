/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/testActions";
import { Button, Row, Col, Input, message, Radio, InputNumber, Table, Divider } from "antd";
import n from "numeral";

const radioStyle = {
	height: "30px",
	lineHeight: "30px"
};

export class QueryTester extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			update: false,
			queryActive: false,
			workbookName: null,
			searchName: null,
			role: null,
			value: 1,
			strict: 0,
			exact: "OFF",
			pool: 0,
			summary: "OFF",
			title: "HINT",
			results: [],
			stats: {},
			query: {},
			location: "",
			radius: "",
			depth: "",
			length: "",
			status: false
		};
	}

	componentDidMount() {}
	CreateQuery(queryString) {
		let options = {
			STRICTNESS: this.state.strict,
			EXACT: this.state.exact,
			SUMMARY: this.state.summary,
			TITLE: this.state.title,
			POOL_SIZE: this.state.pool
		};

		const { workbookName, searchName, role, text } = this.state;
		const data = new FormData();
		data.append("text", text);
		data.append("location", this.state.location);
		data.append("radius", this.state.radius);
		data.append("careerDepth", this.state.depth);
		data.append("careerLength", this.state.length);
		data.append("searchName", searchName);
		data.append("workbookName", workbookName);
		data.append("role", role);

		this.props.actions.extractQuery(data, options).then((response) => {
			message.loading("Query is being processed");
			this.setState(
				{ results: response.results, status: true, stats: response.stats, query: response.query },
				() => message.success("Query Success")
			);
		});
	}

	downloadFile(file, name) {
		let options = {
			direectory: "~/downloads",
			filename: name
		};
		saveFile(file, options, function(err) {
			if (err) throw err;
		});
	}

	onChange = (e) => {
		this.setState({ value: e.target.value });
	};

	getFieldTitle = (label) => {
		if (label === "totalHits") {
			return "Total Hits";
		} else if (label === "maxRelevancy") {
			return "Max";
		} else if (label === "minRelevancy") {
			return "Min";
		} else if (label === "stdDevRelevancy") {
			return "Relevance";
		} else if (label === "varianceRelevancy") {
			return "Variance";
		} else if (label === "aboveMeanCount") {
			return "Mean Count";
		} else if (label === "oneStdDevBelowMaxCount") {
			return "Std 1";
		} else if (label === "twoStdDevBelowMaxCount") {
			return "Std 2";
		} else if (label === "threeStdDevBelowMaxCount") {
			return "Std 3";
		} else if (label === "fiveStdDevBelowMaxCount") {
			return "Std 4";
		} else if (label === "meanRelevancy") {
			return "Mean";
		}
	};

	render() {
		return (
			<Row style={{ minHeight: "85vh" }}>
				{this.state.status === false ? (
					<Row type="flex" align="middle">
						<Col span={11}>
							<Row type="flex" align="middle">
								<Col
									span={24}
									style={{
										background: "white",
										height: "600px",
										padding: 20,
										margin: "2rem 2rem 2rem 2rem",
										borderRadius: 5,
										boxShadow: "0 5px 10px rgba(0, 0, 0, 0.15)"
									}}
								>
									<Row style={{ marginBottom: "2rem" }}>
										<Col style={{ marginRight: "1rem" }}>
											<label style={{ fontWeight: "bold" }}>Workbook Name:</label>{" "}
											<Input onChange={(e) => this.setState({ workbookName: e.target.value })} />
										</Col>
									</Row>
									<Row style={{ marginBottom: "2rem" }}>
										<Col style={{ marginRight: "1rem" }}>
											<label style={{ fontWeight: "bold" }}>Query Name:</label>{" "}
											<Input onChange={(e) => this.setState({ searchName: e.target.value })} />
										</Col>
									</Row>
									<Row style={{ marginBottom: "2rem" }}>
										<Col style={{ marginRight: "1rem" }}>
											<label style={{ fontWeight: "bold" }}>Title:</label>{" "}
											<Input onChange={(e) => this.setState({ role: e.target.value })} />
										</Col>
									</Row>
									<Row style={{ padding: 0 }}>
										<Col>
											<label style={{ fontWeight: "bold" }}>Job Description:</label>{" "}
											<Input.TextArea
												onChange={(e) => this.setState({ text: e.target.value })}
												rows={5}
											/>
										</Col>
									</Row>
									<Row>
										<label style={{ fontWeight: "bold", marginRight: "1rem" }}>
											Activate Advanced Query?
										</label>
										<Radio.Group
											onChange={this.onChange}
											value={this.state.value}
											style={{ marginTop: "2rem" }}
										>
											<Radio value={1}>No</Radio>
											<Radio value={2}>Yes</Radio>
										</Radio.Group>
									</Row>
									<Row>
										<Button onClick={() => this.CreateQuery()} style={{ marginTop: "2rem" }}>
											Create Query
										</Button>
									</Row>
								</Col>
							</Row>
						</Col>
						{this.state.value === 2 ? (
							<Col
								span={11}
								style={{
									height: "600px",
									background: "white",
									padding: 20,
									margin: "2rem 2rem 2rem 4rem",
									borderRadius: 5,
									boxShadow: "0 5px 10px rgba(0, 0, 0, 0.15)"
								}}
							>
								<Row type="flex">
									<h2>Query Options</h2>
								</Row>
								<Row type="flex" align="middle">
									<Col span={24} style={{ background: "white", padding: 20 }}>
										<Row>
											<label
												style={{
													fontWeight: "bold",
													marginRight: "1rem",
													marginBottom: "1rem"
												}}
											>
												Strictness:
											</label>{" "}
											<Radio.Group
												onChange={(e) => this.setState({ strict: e.target.value })}
												value={this.state.strict}
												name="STRICTNESS"
											>
												<Radio style={radioStyle} value={0}>
													0
												</Radio>
												<Radio style={radioStyle} value={1}>
													1
												</Radio>
												<Radio style={radioStyle} value={2}>
													2
												</Radio>
												<Radio style={radioStyle} value={3}>
													3
												</Radio>
											</Radio.Group>
										</Row>
										<Row>
											<label
												style={{
													fontWeight: "bold",
													marginRight: "1rem",
													marginBottom: "1rem"
												}}
											>
												Exact:
											</label>{" "}
											<Radio.Group
												onChange={(e) => this.setState({ exact: e.target.value })}
												value={this.state.exact}
												name="EXACT"
											>
												<Radio style={radioStyle} value={"ON"}>
													ON
												</Radio>
												<Radio style={radioStyle} value={"OFF"}>
													OFF
												</Radio>
											</Radio.Group>
										</Row>
										<Row>
											<label
												style={{
													fontWeight: "bold",
													marginRight: "1rem",
													marginBottom: "1rem"
												}}
											>
												Summary:
											</label>{" "}
											<Radio.Group
												onChange={(e) => this.setState({ summary: e.target.value })}
												value={this.state.summary}
												name="SUMMARY"
											>
												<Radio style={radioStyle} value={"ON"}>
													ON
												</Radio>
												<Radio style={radioStyle} value={"OFF"}>
													OFF
												</Radio>
											</Radio.Group>
										</Row>
										<Row>
											<label
												style={{
													fontWeight: "bold",
													marginRight: "1rem",
													marginBottom: "1rem"
												}}
											>
												Title:
											</label>{" "}
											<Radio.Group
												onChange={(e) => this.setState({ title: e.target.value })}
												value={this.state.title}
												name="TITLE"
											>
												<Radio style={radioStyle} value={"HINT"}>
													Hint
												</Radio>
												<Radio style={radioStyle} value={"FORCE"}>
													Force
												</Radio>
											</Radio.Group>
										</Row>
										<Row>
											<label style={{ fontWeight: "bold", marginRight: "1rem" }}>
												Pool Size:
											</label>{" "}
											<InputNumber
												style={{ width: "100%" }}
												min={1}
												max={1000}
												onChange={(e) => this.setState({ pool: e })}
											/>
										</Row>

										<Row style={{ padding: 0, marginBottom: "1rem" }}>
											<Col>
												<label style={{ fontWeight: "bold" }}>Location:</label>{" "}
												<Input
													onChange={(e) => this.setState({ location: e.target.value })}
													rows={5}
												/>
											</Col>
										</Row>
										<Row style={{ padding: 0, marginBottom: "1rem" }}>
											<Col>
												<label style={{ fontWeight: "bold" }}>Radius:</label>{" "}
												<Input
													onChange={(e) => this.setState({ radius: e.target.value })}
													rows={5}
												/>
											</Col>
										</Row>
										<Row style={{ padding: 0, marginBottom: "1rem" }}>
											<Col>
												<label style={{ fontWeight: "bold" }}>Yrs. Experience:</label>{" "}
												<InputNumber
													style={{ width: "100%" }}
													min={1}
													max={1000}
													onChange={(e) => this.setState({ length: e.toString() })}
												/>
											</Col>
										</Row>
										<Row style={{ padding: 0, marginBottom: "1rem" }}>
											<Col>
												<label style={{ fontWeight: "bold" }}>Num. Jobs:</label>{" "}
												<InputNumber
													style={{ width: "100%" }}
													min={1}
													max={1000}
													onChange={(e) => this.setState({ depth: e.toString() })}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						) : null}
					</Row>
				) : (
					<Row className="results" span={24}>
						<Row style={{ padding: 10 }}>
							<Button onClick={() => this.setState({ status: false })}>New Query</Button>
						</Row>
						<Row span={24} className="stats" style={{ padding: 20 }}>
							{Object.keys(this.state.stats).map((data, index) => {
								if (data === "totalHits") {
									return (
										<Col
											key={index}
											span={6}
											style={{
												background: "white",
												padding: 20,
												margin: 20,
												borderRadius: 8,
												width: "200px",
												height: "100px",
												verticalAlign: "middle",
												boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)"
											}}
										>
											<Row style={{ textAlign: "center" }}>
												<h3>{this.getFieldTitle(data)}</h3>
											</Row>
											<Row style={{ textAlign: "center" }}>
												<h4>{n(this.state.stats[data]).format("0,0")}</h4>
											</Row>
										</Col>
									);
								} else {
									return (
										<Col
											span={3}
											key={index}
											style={{
												background: "white",
												padding: 20,
												margin: 20,
												verticalAlign: "middle",
												borderRadius: 8,
												width: "200px",
												height: "100px",
												boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)"
											}}
										>
											<Row style={{ textAlign: "center" }}>
												<h3>{this.getFieldTitle(data)}</h3>
											</Row>
											<Row style={{ textAlign: "center" }}>
												<h4>{this.state.stats[data]}</h4>
											</Row>
										</Col>
									);
								}
							})}
						</Row>
						{this.state.results.length > 0 ? (
							<Row span={24} className="results-list" style={{ padding: 20, margin: 20 }}>
								{this.state.results.map((data, index) => {
									if (index < 6) {
										const person = data.exportIndividual;
										const score = data.score;
										const relevancy = data.relevancy;

										return (
											<Col
												key={index}
												span={24}
												style={{
													background: "white",
													marginBottom: "2rem",
													borderRadius: 8,
													boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)"
												}}
											>
												<Divider />
												<Row>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Name: </label>
														{person.firstName + " " + person.lastName}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Title: </label>
														{person.currentTitle}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Previous Title: </label>
														{person.previousTitle}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Current Company: </label>
														{person.currentCompany}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>
															Previous Company:{" "}
														</label>
														{person.previousCompany}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Degree: </label>
														{person.degree}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Location: </label>
														{person.location}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Relevancy: </label>
														{person.relevancy}
													</Col>
													<Col span={6} style={{ margin: 10 }}>
														<label style={{ marginRight: "1rem" }}>Score: </label>
														{person.score}
													</Col>
												</Row>
												<Row span={24}>
													<Row style={{ margin: 10 }}>
														<h4>
															<label>Expertise</label>
														</h4>
													</Row>
													<Row span={24} style={{ margin: 10 }}>
														<Col span={24}>
															{person.expertise.split(",").join(", ") || "No Expertise"}
														</Col>
													</Row>
												</Row>
												<Divider />
											</Col>
										);
									}
								})}
							</Row>
						) : null}
					</Row>
				)}
			</Row>
		);
	}
}

QueryTester.propTypes = {};

function mapStateToProps(state) {
	return {
		test: state.queryTester
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryTester);
