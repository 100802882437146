import React, { Component } from "react";
import { Popconfirm, Icon, message, Select, Row, Col, Input, InputNumber, Switch, Tooltip, Slider } from "antd";

const InputGroup = Input.Group;
const Option = Select.Option;
const text = "Would you like to remove these fields from your search?";
class QueryExp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			career_length: false,
			career_length_op: "GTE",
			career_length_input: 1,
			career_length_text: "",
			career_count: false,
			career_count_op: "GTE",
			career_count_input: 1,
			career_count_text: "",
			remove: false
		};
	}

	shouldComponentUpdate(nextProps) {
		const dataChanged = this.props.data !== nextProps.data;
		return dataChanged;
	}

	CheckFilter(subFilter) {
		if (subFilter.mustHave[0]) {
			if (subFilter.mustHave[0].includes(":") === true) {
				return "range";
			} else {
				return "input";
			}
		} else {
			return "input";
		}
	}

	SelectValue(value, key, input) {
		if (value === "GTE") {
			return `Candidates with ${key.split("_").join(" ")} Greater than or equal to ${input}`;
		} else if (value === "LTE") {
			return `Candidates with ${key.split("_").join(" ")} Less than or equal to ${input}`;
		}
	}

	onSwitch(value, subFilter, filterIndex, subFilterIndex) {
		const row = this.props.filters[filterIndex].subCategories[subFilterIndex].mustHave;
		const title = subFilter.key.split("_").join(" ");
		if (value === true) {
			if (row[0]) {
				if (row[0].includes(":") === false) {
					const newValues = [ `GTE 1:LTE 5` ];
					this.props.onUpdateFilter(filterIndex, subFilterIndex, "mustHave", newValues);
				}
			} else {
				const newValues = [ "GTE 1:LTE 5" ];

				this.props.onUpdateFilter(filterIndex, subFilterIndex, "mustHave", newValues);
			}
		} else if (value === false) {
			const newValues = [ "GTE 1" ];
			this.props.onUpdateFilter(filterIndex, subFilterIndex, "mustHave", newValues);
		}
	}

	CheckDefaultSwitch(subFilter) {
		if (subFilter.mustHave.length === 1) {
			const check = subFilter.mustHave[0].includes(":");
			if (check === true) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	onDefault(arr, type, key) {
		if (type === "range") {
			const arrNew = arr[0].split(":");
			const value1 = arrNew[0].split("GTE")[1];
			if (arrNew.length > 1) {
				const value2 = arrNew[1].split("LTE")[1];

				return [ Number(value1), Number(value2) ];
			}
			return [ Number(value1), 5 ];
		} else {
			if (arr.length !== 0) {
				const update = arr[0].split(" ");
				const [ select, input ] = update;
				if (type === "input") {
					if (update.length === 1) {
						return Number(select);
					} else {
						return Number(input);
					}
				} else if (type === "select") {
					if (update.length === 1) {
						return "GTE";
					} else {
						return select;
					}
				}
			} else {
				if (type === "input") {
					return Number(1);
				} else if (type === "select") {
					return "Select";
				}
			}
		}
	}

	removeItems(key, index) {
		this.props.onRemoveFilter(key, index);
		this.setState({
			career_length: false,
			career_length_op: "GTE",
			career_length_input: 1,
			career_length_text: "",
			career_count: false,
			career_count_op: "GTE",
			career_count_input: 1,
			career_count_text: "",
			remove: false
		});
		message.success("Your search has been modified.");
	}

	render() {
		const { key } = this.props.data;
		const { filterIndex } = this.props;
		const { subCategories } = this.props.data;
		// test
		return (
			<div>
				<Col span={23} style={{ marginBottom: "2rem" }}>
					<Popconfirm
						placement="topRight"
						title={text}
						onConfirm={() => this.removeItems(key, filterIndex)}
						okText="Yes"
						cancelText="No"
					>
						<Icon type="delete" theme="outlined" className="queryIcon__delete pull-right" />
					</Popconfirm>
				</Col>
				{subCategories.map((subFilter, subFilterIndex) => (
					<Col span={12} key={subFilter.key + "-" + subFilterIndex} style={{ marginBottom: "4rem" }}>
						<Row>
							<h4 style={{ textTransform: "capitalize" }}>{subFilter.key.split("_").join(" ")}</h4>
						</Row>
						<Row>
							<label style={{ marginRight: "1rem" }}>Ranges:</label>
							<Switch
								checked={this.CheckDefaultSwitch(subFilter)}
								size="small"
								onChange={(value) => {
									this.props.onAddFilter(key);
									this.setState(
										{
											[subFilter.key + "_" + "text"]: ""
										},
										function() {
											this.onSwitch(value, subFilter, filterIndex, subFilterIndex);
										}
									);
								}}
							/>
						</Row>
						<Row span={24}>
							{this.CheckFilter(subFilter) === "input" ? (
								<Col span={20}>
									<Row>
										<InputGroup style={{ marginBottom: "1rem" }} compact>
											<InputNumber
												min={0}
												text="left"
												value={
													subFilter["mustHave"].length > 0 ? (
														subFilter["mustHave"][0].split(" ")[1]
													) : (
														1
													)
												}
												onChange={(value) => {
													this.props.onAddFilter(key);
													this.setState(
														{
															[subFilter.key + "_" + "input"]: value,
															[subFilter.key +
															"_" +
															"text"]: `Candidates with ${subFilter.key
																.split("_")
																.join(" ")} ${this.state[subFilter.key + "_" + "op"] ===
															"GTE"
																? "greater than or equal to"
																: "Less than or equal to"} ${value}`
														},
														function() {
															const operator = this.state[subFilter.key + "_" + "op"];
															const inputValue = this.state[
																subFilter.key + "_" + "input"
															];
															const passData = [ `${operator} ${inputValue}` ];
															this.props.onUpdateFilter(
																filterIndex,
																subFilterIndex,
																"mustHave",
																passData
															);
														}
													);
												}}
											/>
											<Select
												defaultValue={this.onDefault(subFilter.mustHave, "select")}
												onSelect={(value) => {
													this.props.onAddFilter(key);
													this.setState(
														{
															[subFilter.key + "_" + "op"]: value
														},
														function() {
															const operator = this.state[subFilter.key + "_" + "op"];
															const inputValue = this.state[
																subFilter.key + "_" + "input"
															];
															const passData = [ `${operator} ${inputValue}` ];

															this.setState({
																[subFilter.key + "_" + "text"]: this.SelectValue(
																	value,
																	subFilter.key,
																	this.state[subFilter.key + "_" + "input"]
																)
															});

															this.props.onUpdateFilter(
																filterIndex,
																subFilterIndex,
																"mustHave",
																passData
															);
														}
													);
												}}
											>
												<Option key={subFilter.key} value="GTE">
													or more
												</Option>
												<Option value="LTE">or less</Option>
											</Select>
										</InputGroup>
									</Row>
									<Row>
										<span
											style={{
												fontStyle: "italic",
												fontSize: "1.10rem"
											}}
										>
											{this.state[subFilter.key + "_" + "text"]}
										</span>
									</Row>
								</Col>
							) : (
								<Row>
									<Col span={20}>
										<Slider
											range
											dots={true}
											min={1}
											max={20}
											defaultValue={
												subFilter["mustHave"].length > 0 ? (
													[
														parseInt(
															subFilter["mustHave"][0].split(":").join(" ").split(" ")[1]
														),
														parseInt(
															subFilter["mustHave"][0].split(":").join(" ").split(" ")[3]
														)
													]
												) : (
													[ 0, 5 ]
												)
											}
											onChange={(value) => {
												const field_label = subFilter.key.split("_").join(" ");
												this.props.onAddFilter(key);
												this.setState(
													{
														[subFilter.key +
														"_" +
														"text"]: `Candidates with a ${field_label} between ${value[0]} and ${value[1]}`
													},
													function() {
														const passData = [ `GTE ${value[0]}:LTE ${value[1]}` ];
														this.props.onUpdateFilter(
															filterIndex,
															subFilterIndex,
															"mustHave",
															passData
														);
													}
												);
											}}
										/>
										<span
											style={{
												fontStyle: "italic",
												fontSize: "1.10rem"
											}}
										>
											{this.state[subFilter.key + "_" + "text"]}
										</span>
									</Col>
								</Row>
							)}
						</Row>
					</Col>
				))}
			</div>
		);
	}
}

export default QueryExp;
