import initialState from "./initialState";
import { GET_GHKEY, GET_GHCLIENTS, UPDATE_GHOPTIONS, UPDATE_GHCLIENT } from "../constants/actionTypes";

export default function greenhouseReducer(state = initialState.greenHouse, action) {
	switch (action.type) {
		case GET_GHKEY:
			return action.payload;

		case UPDATE_GHCLIENT:
			return action.payload;

		case GET_GHCLIENTS:
			return { ...state, clients: action.payload };

		case UPDATE_GHOPTIONS:
			return { ...state, clients: action.payload };
		default:
			return state;
	}
}
