import React, { Component } from "react";
import { Tabs, Icon, Row, Col, Button, Tag } from "antd";
import QueryTabPane from "./QueryTabPane";
import m from "moment";
import QueryStats from "./QueryStats";

const TabPane = Tabs.TabPane;

const iconStyle = { float: "right", marginLeft: 25, padding: 5 };

class QueryTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			view: "left",
			mustHave: [],
			shouldHave: [],
			mustNotHave: []
		};
	}

	componentWillReceiveProps() {}

	handleModeChange = (e) => {
		const mode = e.target.value;
		this.setState({ mode });
	};

	CreateBanks() {
		this.props.filters.forEach((item, index) => {
			const exclude = [ "location", "yearsExperience", "languages", "education" ];

			if (exclude.indexOf(item.key) === -1) {
				item["subCategories"].forEach((category) => {
					const types = [ "mustHave", "shouldHave", "mustNotHave" ];
					types.forEach((type) => {
						if (category[type].length > 0) {
							category[type].forEach((term) => {
								this.SaveBank(type, term);
							});
						}
					});
				});
			}
		});
	}

	SaveBank(type, term) {
		const arr = this.state[type];
		if (term !== "undefined") {
			if (this.state[type].indexOf(term) < 0 && typeof term === "string") {
				arr.push(term);
				this.setState({ [type]: arr });
			}
		}
	}

	getTabName(key) {
		if (key === "workBio") {
			return "Work Bio";
		} else if (key === "education") {
			return "Education";
		} else if (key === "industry") {
			return "Company";
		} else if (key === "jobTitles") {
			return "Job Titles";
		} else if (key === "yearsExperience") {
			return "Experience";
		} else if (key === "languages") {
			return "Languages";
		} else if (key === "location") {
			return "Location";
		}
	}

	render() {
		this.CreateBanks();
		const cards = this.props.cards;

		return (
			<Row span={24} style={{ borderRadius: "0px 0px 10px 0px", flex: 1 }}>
				<Row
					style={{
						marginLeft: "2rem",
						background: "#32b9ff",
						marginTop: "1rem",
						marginBottom: "1rem",
						marginRight: "2rem"
					}}
				>
					<Col span={5} style={{ paddingLeft: 40, paddingTop: 15 }}>
						<h3 style={{ color: "white", fontWeight: "bold" }}>DISCOVER</h3>
					</Col>
					<Col span={19} />
				</Row>
				<Col
					span={19}
					style={{
						height: "100%",
						background: "white",
						marginLeft: "0rem",
						marginTop: "1rem",
						borderRight: "1px solid #e7e7e7",
						borderBottom: "1px solid #e7e7e7",
						marginLeft: "2rem",
						borderRadius: 5
					}}
				>
					<Row
						span={20}
						style={{
							margin: "0rem 0rem",
							padding: "2rem 0rem",
							borderRight: "1px solid #e7e7e7",
							border: "1px solid #e7e7e7"
						}}
					>
						<span style={{ fontSize: "2rem", padding: "0 2rem" }}>{this.props.query.name}</span>
						<Button style={{ float: "right", marginRight: "2rem" }} onClick={this.props.onRunSearch}>
							<Icon type="search" /> View Results
						</Button>
					</Row>
					<Row
						span={20}
						style={{
							borderTop: "1px solid #e7e7e7",
							borderLeft: "1px solid #e7e7e7"
						}}
					>
						<Tabs
							defaultActiveKey={cards[0] || "workBio"}
							tabPosition={this.state.view}
							type="list"
							size="small"
							animated={true}
						>
							{this.props.filters.map((item, i) => (
								<TabPane
									tab={
										<span
											style={{
												fontWeight: 400,
												fontSize: 16
											}}
										>
											{this.getTabName(item.key)}{" "}
											{this.props.cards.indexOf(item.key) > -1 ? (
												<Tag style={{ float: "right", marginLeft: "2rem" }} color="#70b6bd">
													Active
												</Tag>
											) : (
												<Tag style={{ float: "right", marginLeft: "2rem" }}>Inactive</Tag>
											)}
										</span>
									}
									key={item.key}
								>
									<QueryTabPane
										key={item.key}
										data={item}
										onUpdateFilter={this.props.onUpdateFilter}
										onRemoveFilter={this.props.onRemoveFilter}
										onAddFilter={this.props.onAddFilter}
										filters={this.props.filters}
										sharedState={this.state}
										createBank={this.CreateBanks.bind(this)}
									/>
								</TabPane>
							))}
						</Tabs>
					</Row>
				</Col>
				<QueryStats preview={this.props.preview} />
			</Row>
		);
	}
}

export default QueryTabs;
