import React from "react";
import { PropTypes } from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import * as actions from "../../actions/sessionActions";
import * as userActions from "../../actions/userActions";
import { Row, Col, Input, Button, Form, Icon, Checkbox, message } from "antd";
import { Image } from "react-bootstrap";
import logo from "../../images/ai4jobs-color-logo.png";
import CookieNotice from "../CookieNotice";

const FormItem = Form.Item;

export class SFLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			credentials: { email: "", password: "" },
			warned: false,
			ref: this.props.location.search.split("?url=").join().split("'").join("").split(",").join("")
		};

		if (this.props.isAuthenticated) {
			//	this.props.history.push("/workbooks");
		}
	}

	componentDidMount() {
		console.log(this.state);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isAuthenticated) {
			// this.props.history.push(`/salesforce/login?key=${localStorage.getItem("accessToken")}`);
		}
		window.analytics.page("Login Page");
	}

	onChangeCredentials = (event) => {
		const field = event.target.type;
		const credentials = this.state.credentials;
		credentials[field] = event.target.value;
		return this.setState({ credentials: credentials });
	};

	onLogin = (event) => {
		event.preventDefault();

		this.props.form.validateFields((err) => {
			if (!err) {
				this.props.actions
					.loginUser(this.state.credentials)
					.then(() => {
						this.successMessage();
						this.props.userActions.getUser();
						const url = `${"https://" +
							this.state.ref +
							"/" +
							localStorage.getItem("accessToken") +
							`?ref=ai4jobs.me/salesforce/login`}`;

						window.location.assign(url);
					})
					.catch(this.warningMessage);
			}
		});
	};

	onForgotPassword = () => {
		this.props.history.push("/forgotpassword");
	};

	warningMessage = () => {
		message.warning("Please check your email and/or password.");
	};

	successMessage = () => {
		message.success("You are logged in!");
	};

	render() {
		console.log(this.props);
		const { getFieldDecorator } = this.props.form;
		const { email, password } = this.state.credentials;
		return (
			<div style={{ marginBottom: "5rem" }}>
				{0 > 1 ? <CookieNotice /> : null}
				<div className="login-page">
					<Row type="flex" justify="center">
						<Col span={7} style={{ marginBottom: "1rem" }}>
							<Image className="logo" src={logo} responsive />
						</Col>
					</Row>
					<Row type="flex" justify="center">
						<Col span={8} className="login-box">
							<Form onSubmit={(e) => this.onLogin(e)} className="login-form">
								<FormItem>
									{getFieldDecorator("email", {
										rules: [ { required: true, message: "Please input your email!" } ]
									})(
										<Input
											prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
											style={{ paddingTop: "2px" }}
											type="email"
											placeholder="Email"
											onChange={this.onChangeCredentials}
										/>
									)}
								</FormItem>

								<FormItem>
									{getFieldDecorator("password", {
										rules: [ { required: true, message: "Please input your password!" } ]
									})(
										<Input
											prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
											style={{ paddingTop: "2px" }}
											type="password"
											placeholder="Password"
											name="password"
											onChange={this.onChangeCredentials}
										/>
									)}
								</FormItem>

								<FormItem>
									<Row style={{ marginBottom: "1.5rem" }}>
										{getFieldDecorator("remember", {
											valuePropName: "checked",
											initialValue: true
										})(
											<Checkbox>
												<span style={{ fontWeight: 300 }}>Remember me</span>
											</Checkbox>
										)}
										<Link
											className="link__style--forgot"
											href=""
											style={{ float: "right" }}
											to={"/forgotpassword"}
										>
											Forgot password
										</Link>
									</Row>
									<Row>
										{email.includes("@") && password.length > 0 ? (
											<Button
												htmlType="submit"
												className="button__style--login"
												style={{ width: "100%" }}
											>
												Log in
											</Button>
										) : (
											<Button
												disabled
												htmlType="submit"
												className="button__style--disabled"
												style={{ width: "100%" }}
											>
												Log in
											</Button>
										)}
									</Row>
								</FormItem>
							</Form>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

SFLogin.propTypes = {
	actions: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	history: ReactRouterPropTypes.history.isRequired,
	currentUser: PropTypes.object.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	form: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		currentUser: state.user,
		isAuthenticated: state.session.auth
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		userActions: bindActionCreators(userActions, dispatch)
	};
}

const LoginForm = Form.create()(SFLogin);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
