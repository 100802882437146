import { SET_USER, CLEAR_USER, GET_GHKEY } from "../constants/actionTypes";
import initialState from "./initialState";

export default function userReducer(state = initialState.user, action) {
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				username: action.payload.username,
				fullName: action.payload.fullName,
				phone: action.payload.phone,
				email: action.payload.email,
				team: action.team,
				usage: action.usage
			};

		case CLEAR_USER:
			return { ...state, username: "", fullName: "", phone: "", email: "" };

		default:
			return state;
	}
}
