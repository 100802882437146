import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

function getWorkbooksApi(dispatch, templates) {
	axios
		.get(api.WORKBOOK_DETAILS, apiUtils.getConfig())
		.then((res) => {
			return dispatch({
				type: types.GET_WORKBOOKS,
				payload: res.data
			});
		})
		.catch((error) => {
			throw error;
		});
}

export function getWorkbooks(templates) {
	return function(dispatch) {
		getWorkbooksApi(dispatch, templates);
	};
}

export function createWorkbook(data) {
	return function(dispatch) {
		return axios
			.post(api.WORKBOOK, data, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
				return Promise.resolve();
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function updateWorkbook(data) {
	return function(dispatch) {
		axios
			.put(`${api.WORKBOOK}/${data.id}`, data, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function duplicateWorkbook(workbook) {
	return function(dispatch) {
		axios
			.post(`${api.WORKBOOK}/${workbook.id}/copy`, workbook, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function deleteWorkbook(workbook) {
	return function(dispatch) {
		axios
			.delete(`${api.WORKBOOK}/${workbook.id}`, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function createQuery(workbook, data, templates) {
	return function(dispatch) {
		axios
			.post(api.query(workbook.id, templates), data, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				console.log(error);
			});
	};
} //

export function updateQuery(workbook, data) {
	return function(dispatch) {
		axios
			.put(`${api.query(workbook.id)}/${data.id}`, data, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function duplicateQuery(workbook, data) {
	return function(dispatch) {
		axios
			.post(api.copyQuery(workbook.id, data.id), data, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				console.log(error);
			});
	};
}

export function deleteQuery(workbook, query) {
	return function(dispatch) {
		axios
			.delete(`${api.query(workbook.id)}/${query.id}`, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function shareQuery(workbook, query, team) {
	return function(dispatch) {
		axios
			.post(
				api.shareQuery(workbook.id, query.id),
				{
					name: query.name,
					description: "Shared Query",
					teamMembers: team
				},
				apiUtils.getConfig()
			)
			.catch((error) => {
				throw error;
			});
	};
}

export function shareWorkbook(team, workbookId, workbookName) {
	return function(dispatch) {
		axios
			.post(
				api.shareWorkbook(workbookId),
				{
					name: workbookName,
					description: "Shared Workbook",
					teamMembers: team
				},
				apiUtils.getConfig()
			)
			.catch((error) => {
				throw error;
			});
	};
}

export function moveQuery(query, workbook, newWorkbook, type) {
	return function(dispatch) {
		axios
			.post(api.moveQuery(query, workbook, type), { targetWorkbookId: newWorkbook }, apiUtils.getConfig())
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function copyQuery(query, workbook, newWorkbook, type, name, desc) {
	return function(dispatch) {
		axios
			.post(
				api.moveQuery(query, workbook, type),
				{
					name: "Copy of " + name,
					description: desc,
					targetWorkbookId: newWorkbook,
					template: false
				},
				apiUtils.getConfig()
			)
			.then(() => {
				getWorkbooksApi(dispatch);
			})
			.catch((error) => {
				throw error;
			});
	};
}
