export function getHeaders() {
  return {
    "Authorization": "Bearer " + localStorage.getItem('accessToken'),
    "Content-Type": "application/json"
  };
}

export function getConfig() {
  return {
    headers: getHeaders()
  };
}
