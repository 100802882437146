import React, { Component } from "react";
import { Input, Row, Col, Button, Checkbox, message, Select, Divider, Spin, Icon, Radio } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import * as api from "../../constants/apiConstants";
import * as apiUtils from "../../utils/apiUtils";
import * as actions from "../../actions/userActions";
import moment from "moment";
const { Options } = Select;
const inputStyle = {
	padding: "1rem .5rem",
	border: "none",
	boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
	width: "50%",
	marginBottom: "2rem"
};

const stateBox = {
	display: "flex",
	margin: 10,
	padding: 20,
	height: 200,
	width: 300,
	background: "white",
	borderRadius: 4,
	boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
	alignItems: "center",
	justifyContent: "center",
	justifyItems: "center",
	textAlign: "center",
	flexFlow: "wrap",
	marginRight: "5rem"
};

const stateValue = {
	fontSize: "8rem",
	textAlign: "center"
};

const statLabel = {
	fontSize: "2rem",
	color: "#8492a2",
	fontWeight: "bold",
	textalign: "center"
};

const IND = <Icon className="animated__icon" type="loading" style={{ fontSize: 24, color: "#8492a2" }} spin />;

// onBehalfDemo: "4409336002",
// id: "4351773002",
// key: "8f9f6346fdaecb4ad29b01bee6023f94-2",
// tip: "Establishing Connection"

class GreenhouseEstimate extends Component {
	state = {
		module: null,
		apiKey: null,
		id: null,
		token: localStorage.getItem("accessToken"),
		loading: false,
		since: "30",
		complete: false,
		fetch: {
			jobs: null,
			posts: null,
			candidates: null,
			estimatedTimeSec: null
		}
	};

	moduleSelect(value) {
		this.setState({ module: value });
	}

	fetchEntire() {
		const sinceWhen = moment().subtract(this.state.since, "days").format("YYYY-MM-DD") + "T00:00:00";
		console.log(sinceWhen);
		let data = {
			atsType: "GHIO",
			sinceDate: sinceWhen
		};
		axios.post(api.fullEstimate(this.state.apiKey), data, apiUtils.getConfig()).then((res) => {
			console.log(api.fullEstimate(this.state.apiKey), data, apiUtils.getConfig());
			console.log(res.data);
			this.setState({ fetch: res.data, complete: true });
		});
	}

	fetchJob() {
		const sinceWhen = moment().subtract(this.state.since, "days").format("YYYY-MM-DD") + "T00:00:00";
		let data = {
			atsType: "GHIO",
			sinceDate: sinceWhen
		};
		axios.post(api.jobEstimate(this.state.apiKey, this.state.id), data, apiUtils.getConfig()).then((res) => {
			this.setState({ fetch: res.data, complete: true });
		});
	}

	onLoad() {
		this.setState({ tip: "Establishing Connection", loading: true }, () => {
			for (let i = 0; i < 7; i++) {
				setTimeout(() => {
					if (this.state.loading === true) {
						const phrases = [
							"Establishing Connection",
							"Collecting Greenhouse Data",
							"Counting Jobs",
							"Counting applicants",
							"Creating Estimate"
						];
						if (i < 5) {
							this.setState({ tip: phrases[i] });
						}
						if (this.state.complete === true && i > 5) {
							this.setState({ loading: false });
						}
					}
				}, i * 5000);
			}
		});
	}

	render() {
		return (
			<Row type="flex" align="middle" style={{ minHeight: "90vh" }}>
				{this.state.loading === true ? (
					<Col
						span={24}
						style={{
							width: "100%",
							height: "80vh"
						}}
					>
						<Col
							span={24}
							style={{
								height: "100%",
								padding: 20,
								borderRadius: 5
							}}
						>
							<div className="example">
								<Spin spinning={this.state.loading} indicator={IND} />
								{this.state.loading === true ? (
									<h2 className="heading__animation">{this.state.tip}</h2>
								) : (
									<div>
										<h1>{this.state.tip}</h1>
										<Button
											ghost
											style={{ borderColor: "#8492a2", color: "#8492a2" }}
											onClick={() => this.setState({ view: true })}
										>
											New Job
										</Button>
									</div>
								)}
							</div>
						</Col>
					</Col>
				) : null}{" "}
				{this.state.complete === false && this.state.loading === false ? (
					<Col
						span={24}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column"
						}}
					>
						<Row>
							<Col
								style={{
									background: "white",
									minWidth: 750,
									padding: 40,
									borderRadius: 5,
									boxShadow: "0px 0px 10px rgba(0,0,0,0.15)"
								}}
							>
								<Col>
									{this.state.module !== null ? (
										<span
											onClick={() => this.setState({ module: null })}
											style={{
												fontSize: "1rem",
												color: "#1890ff",
												float: "right",
												alignSelf: "end",
												cursor: "pointer"
											}}
										>
											Change Option...
										</span>
									) : null}
									<h3 style={{ fontWeight: "bold" }}>Welcome to Greenhouse Estimator</h3>
									<Divider style={{ marginTop: "0rem" }} />
									{this.state.module === null ? (
										<Row>
											<p>
												Would you like an estimate for a specific job or for a Full Greenhouse
												Account?
											</p>
											<Radio.Group
												onChange={(e) => this.moduleSelect(e.target.value)}
												style={{ marginTop: "1rem" }}
											>
												<Radio value={0}>Single Job</Radio>
												<Radio value={1}>Full Account</Radio>
											</Radio.Group>
										</Row>
									) : null}
									{this.state.module === 0 && this.state.loading === false ? (
										<Row>
											<p>
												Please fill in the information below regarding your Greenhouse API key,
												as well as your job ID.
											</p>
											<Row>
												<Input
													style={inputStyle}
													placeholder="Greenhouse API Key"
													onChange={(e) => this.setState({ apiKey: e.target.value })}
												/>
											</Row>
											<Row>
												<Input
													style={inputStyle}
													placeholder="Greenhouse Job ID"
													onChange={(e) => this.setState({ id: e.target.value })}
												/>
											</Row>
											<Row>
												<p>How many days back do you want an estimate for?</p>
												<Select
													defaultValue="30"
													style={{ border: "none", width: "50%" }}
													onChange={(e) => this.setState({ since: e.target.value })}
												>
													<Option value="30">30 Days</Option>
													<Option value="60">60 Days</Option>
													<Option value="90">90 Days</Option>
													<Option value="365">1 Year</Option>
												</Select>
											</Row>
											{this.state.apiKey !== null && this.state.id !== null ? (
												<Row>
													<Button
														ghost
														onClick={() => this.fetchJob()}
														style={{
															color: "#8492a2",
															borderColor: "#8492a2",
															marginTop: "2rem"
														}}
													>
														Start
													</Button>
												</Row>
											) : null}
										</Row>
									) : null}
									{this.state.module === 1 && this.state.loading === false ? (
										<Row>
											<p>
												Please fill in the information below regarding your Greenhouse API key.
											</p>
											<Row>
												<Input
													style={inputStyle}
													placeholder="Greenhouse API Key"
													onChange={(e) => this.setState({ apiKey: e.target.value })}
												/>
											</Row>
											<Row>
												<p>How many days back do you want an estimate for?</p>
												<Select
													defaultValue="30"
													style={{ border: "none", width: "50%" }}
													onChange={(e) => this.setState({ since: e.target.value })}
												>
													<Option value="30">30 Days</Option>
													<Option value="60">60 Days</Option>
													<Option value="90">90 Days</Option>
													<Option value="365">1 Year</Option>
												</Select>
											</Row>
											{this.state.apiKey !== null ? (
												<Row>
													<Button
														onClick={() => this.fetchEntire()}
														ghost
														style={{
															color: "#8492a2",
															borderColor: "#8492a2",
															marginTop: "2rem"
														}}
													>
														Start
													</Button>
												</Row>
											) : null}
										</Row>
									) : null}
								</Col>
							</Col>
						</Row>
					</Col>
				) : null}
				{this.state.complete === true && this.state.loading === false ? (
					<Row type="flex" align="middle" style={{ justifyContent: "center", flex: 1 }}>
						<Row style={{ textAlign: "center", width: "100%", marginBottom: "2rem" }}>
							<h1 style={{ fontWeight: "bold" }}>Your Estimate Is Ready!</h1>
						</Row>
						<Col span={24} style={stateBox}>
							<Row style={{ width: "100%" }}>
								<span style={stateValue}>{this.state.fetch.jobs}</span>
							</Row>
							<Row style={{ width: "100%" }}>
								<span style={statLabel}>Job Count</span>
							</Row>
						</Col>
						<Col style={stateBox}>
							<Row style={{ width: "100%" }}>
								<span style={stateValue}>{this.state.fetch.posts}</span>
							</Row>
							<Row style={{ width: "100%" }}>
								<span style={statLabel}>Job Posts</span>
							</Row>
						</Col>
						<Col style={stateBox}>
							<Row style={{ width: "100%" }}>
								<span style={stateValue}>{this.state.fetch.candidates}</span>
							</Row>
							<Row style={{ width: "100%" }}>
								<span style={statLabel}>Applicant Count</span>
							</Row>
						</Col>
						{Math.floor(this.state.fetch.estimatedTimeSec / 60) > 0 ? (
							<Col style={stateBox}>
								<Row style={{ width: "100%" }}>
									<span style={stateValue}>{Math.floor(this.state.fetch.estimatedTimeSec / 60)}</span>
								</Row>
								<Row style={{ width: "100%" }}>
									<span style={statLabel}>Minutes</span>
								</Row>
							</Col>
						) : (
							<Col style={stateBox}>
								<Row style={{ width: "100%" }}>
									<span style={stateValue}>{this.state.fetch.estimatedTimeSec}</span>
								</Row>
								<Row style={{ width: "100%" }}>
									<span style={statLabel}>Seconds</span>
								</Row>
							</Col>
						)}
						<Row style={{ textAlign: "center", width: "100%", marginTop: "2rem" }}>
							<Button
								ghost
								style={{
									color: "#8492a2",
									borderColor: "#8492a2",
									marginTop: "2rem"
								}}
								onClick={() => this.setState({ complete: false })}
							>
								Start New Estimate
							</Button>
						</Row>
					</Row>
				) : null}
			</Row>
		);
	}
}

GreenhouseEstimate.propTypes = {};

function mapStateToProps(state) {
	return {
		greenhouse: state.greenHouse,
		user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenhouseEstimate);
