import React from "react";
import { PropTypes, func } from "prop-types";
import { Modal, Button, Row, Form, Input } from "antd";

const FormItem = Form.Item;
export class DuplicateFormModal extends React.Component {
	constructor(props) {
		super(props);

		this.updatedDuplicate = null;
	}

	state = {
		alert: false,
		name: null
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.duplicate) {
			if (nextProps.duplicate.query) {
				this.updatedDuplicate = { ...nextProps.duplicate.query };
			} else {
				this.updatedDuplicate = { ...nextProps.duplicate.workbook };
			}
		}
	}

	handleChange = (event) => {
		if (event.target.value.length > 0) {
			this.setState({ name: event.target.value });
			this.updatedDuplicate[event.target.name] = event.target.value;
		} else {
			this.setState({ name: null });
		}
	};

	onKeyEvent(e) {
		if (e.key === "Enter") {
			if (this.props.duplicate.query) {
				this.props.onSave(this.props.duplicate.workbook, this.updatedDuplicate);
			} else {
				this.props.onSave(this.updatedDuplicate, null);
			}
		}
	}

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0
				},
				sm: {
					span: 16,
					offset: 8
				}
			}
		};
		return (
			<Row>
				{this.props.duplicate.workbook ? (
					<Modal
						title={this.props.duplicate.query ? "Duplicate Query" : "Duplicate Workbook"}
						visible={this.props.duplicate != null}
						onCancel={() => this.props.onCancel()}
						footer={[
							<Button
								disabled={this.state["name"] === null ? true : false}
								key="back"
								onClick={() => this.props.onCancel()}
							>
								Cancel
							</Button>,

							<Button
								key="submit"
								type="primary"
								disabled={this.state["name"] === null ? true : false}
								id={this.state["name"] === null ? "disabled__btn" : "primary__btn"}
								onClick={() =>
									this.props.duplicate.query
										? this.props.onSave(this.props.duplicate.workbook, this.updatedDuplicate)
										: this.props.onSave(this.updatedDuplicate, null)}
							>
								Save
							</Button>
						]}
					>
						<Form>
							<FormItem {...formItemLayout} label="Name">
								<Input
									placeholder={
										this.props.duplicate.query ? (
											"Enter Your Query Name"
										) : (
											"Enter Your Workbook name"
										)
									}
									name="name"
									style={{ background: "#fafafa" }}
									onChange={this.handleChange.bind(this)}
									onKeyDown={(e) => this.onKeyEvent(e)}
								/>
							</FormItem>
						</Form>
					</Modal>
				) : null}
			</Row>
		);
	}
}

DuplicateFormModal.propTypes = {
	duplicate: PropTypes.object,
	onSave: func.isRequired,
	onCancel: func.isRequired
};

const DuplicateForm = Form.create()(DuplicateFormModal);

export default DuplicateForm;
