import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";

export function sendResetInstructions(email) {
  return function(dispatch) {
    dispatch({
      type: types.SEND_RESET_EMAIL
    });

    let data = {
      email: email
    };

    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=="
      }
    };

    return axios
      .post(api.PASSWORD_RESET_EMAIL, data, config)
      .then(res => {
        if (res.status == 200) {
          dispatch({
            type: types.SEND_RESET_EMAIL_COMPLETE,
            payload: {
              hasError: false,
              message: `Reset instructions will soon be sent to ${email}.`
            }
          });
          return Promise.resolve();
        } else {
          dispatch({
            type: types.SEND_RESET_EMAIL_COMPLETE,
            payload: {
              hasError: true,
              message:
                "We were unable to send reset instructions. Please contact your administrator."
            }
          });
          return Promise.reject();
        }
      })
      .catch(() => {
        dispatch({
          type: types.SEND_RESET_EMAIL_COMPLETE,
          payload: {
            hasError: true,
            message:
              "We were unable to send reset instructions. Please contact your administrator."
          }
        });
        return Promise.reject();
      });
  };
}

export function resetPassword(password, guid) {
  return function(dispatch) {
    dispatch({
      type: types.RESET_PASSWORD
    });

    let data = {
      clid: guid,
      password: password
    };

    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: "Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=="
      }
    };

    return axios
      .post(api.RESET_PASSWORD, data, config)
      .then(res => {
        if (res.status == 200) {
          dispatch({
            type: types.RESET_PASSWORD_COMPLETE,
            payload: {
              hasError: false,
              message: `Your password was reset.`
            }
          });
          return Promise.resolve();
        } else {
          dispatch({
            type: types.RESET_PASSWORD_COMPLETE,
            payload: {
              hasError: true,
              message: "Password reset error"
            }
          });
          return Promise.reject();
        }
      })
      .catch(error => {
        dispatch({
          type: types.RESET_PASSWORD_COMPLETE,
          payload: {
            hasError: true,
            message: error.message
          }
        });
        return Promise.reject();
      });
  };
}

export function setPasswordResetError(error) {
  return function(dispatch) {
    dispatch({
      type: types.SET_RESET_PASSWORD_ERROR,
      payload: {
        hasError: true,
        message: error
      }
    });
    return Promise.reject();
  };
}

export function clearPasswordResetError() {
  return function(dispatch) {
    dispatch({
      type: types.SET_RESET_PASSWORD_ERROR,
      payload: {
        hasError: false,
        message: ""
      }
    });
    return Promise.reject();
  };
}
