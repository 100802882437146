import initialState from "./initialState";
import { GET_GHOPTIONS } from "../constants/actionTypes";

export default function optionsReducer(state = initialState.options, action) {
	switch (action.type) {
		case GET_GHOPTIONS:
			return action.payload;

		default:
			return state;
	}
}
