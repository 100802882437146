import React from "react";
import { PropTypes, func } from "prop-types";
import { Modal, Button, Row, Col, Form, Input } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;
export class QueryFormModal extends React.Component {
  constructor(props) {
    super(props);

    this.updatedQuery = null;
  }
  state = {
    name: null
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.workbookQuery && nextProps.workbookQuery.query) {
      this.updatedQuery = { ...nextProps.workbookQuery.query };
    }
  }

  handleChange = event => {
    if (event.target.value.length > 0) {
      this.setState({ name: event.target.value });
      this.updatedQuery[event.target.name] = event.target.value;
    } else {
      this.setState({ name: null });
    }
  };

  onKeyEvent(e) {
    if (e.key === "Enter") {
      this.props.onSave(this.props.workbookQuery.workbook, this.updatedQuery);
    }
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 10 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <Row>
        {this.props.workbookQuery.query ? (
          <Modal
            title={
              this.props.workbookQuery.query.name !== "New Query"
                ? "Edit Query"
                : "New Query"
            }
            visible={this.props.workbookQuery.query !== null}
            onOk={() =>
              this.props.onSave(
                this.props.workbookQuery.workbook,
                this.updatedQuery
              )
            }
            onCancel={() => this.props.onCancel()}
            footer={[
              <Button
                disabled={this.state["name"] === null ? true : false}
                key="back"
                onClick={() => this.props.onCancel()}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={this.state["name"] === null ? true : false}
                id={
                  this.state["name"] === null ? "disabled__btn" : "primary__btn"
                }
                onClick={() =>
                  this.props.onSave(
                    this.props.workbookQuery.workbook,
                    this.updatedQuery
                  )
                }
              >
                Save
              </Button>
            ]}
          >
            <Form>
              <FormItem {...formItemLayout} label="Name">
                <Input
                  placeholder="Enter Your Query Name"
                  name="name"
                  style={{ background: "#fafafa" }}
                  defaultValue={
                    this.props.workbookQuery.query.name !== "New Query"
                      ? this.props.workbookQuery.query.name
                      : null
                  }
                  onKeyDown={e => this.onKeyEvent(e)}
                  onChange={this.handleChange.bind(this)}
                />
              </FormItem>
              <FormItem {...formItemLayout} label="Description">
                <TextArea
                  rows={2}
                  style={{ background: "#fafafa" }}
                  name="description"
                  placeholder="Enter Your Query Description"
                  onKeyDown={e => this.onKeyEvent(e)}
                  defaultValue={
                    this.props.workbookQuery.query.description !==
                    "New query description"
                      ? this.props.workbookQuery.query.description
                      : null
                  }
                  onChange={this.handleChange.bind(this)}
                />
              </FormItem>
            </Form>
          </Modal>
        ) : null}
      </Row>
    );
  }
}

QueryFormModal.propTypes = {
  workbookQuery: PropTypes.object,
  onSave: func.isRequired,
  onCancel: func.isRequired
};

const EditForm = Form.create()(QueryFormModal);

export default EditForm;
