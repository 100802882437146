import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function extractQuery(form, options) {
	let getHeaders = {
		headers: {
			Authorization: "Bearer " + localStorage.getItem("accessToken"),
			"Content-Type": "multipart-formdata",
			Accept: "application/json",
			"X-AI4Jobs-Match-Options": JSON.stringify(options)
		}
	};
	console.log(JSON.stringify(options));
	return function(dispatch) {
		return axios
			.post(api.getJobReq(), form, getHeaders)
			.then((res) => {
				dispatch({
					type: types.TEST_ACTION,
					payload: res.data
				});

				return res.data;
			})
			.catch((e) => e);
	};
}
