import initialState from './initialState';
import { SEND_RESET_EMAIL, SEND_RESET_EMAIL_COMPLETE} from '../constants/actionTypes';

export default function passwordLinkReducer(state = initialState.passwordLink, action) {
  switch (action.type) {
    case SEND_RESET_EMAIL:
      return {...state, isLoading: true, hasError: false, message: ''};

    case SEND_RESET_EMAIL_COMPLETE:
      return {...state, 
        isLoading: false,
        hasError: action.payload.hasError,
        message: action.payload.message
      };

    default:
      return state;
  }
}
