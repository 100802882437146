import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import qs from "qs";

export function loginUser(credentials) {
	return function(dispatch) {
		let data = {
			grant_type: "password",
			username: credentials.email,
			password: credentials.password,
			scope: "read write",
			client_id: "twg-trusted-client"
		};

		let config = {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				Accept: "*/*",
				Authorization: "Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=="
			}
		};

		return axios
			.post(api.AUTHENTICATION, qs.stringify(data), config)
			.then((res) => {
				localStorage.setItem("accessToken", res.data.access_token);
				dispatch({ type: types.LOGIN_SUCCESS, payload: false });

				return Promise.resolve();
			})
			.catch((error) => {
				dispatch({ type: types.LOGIN_ATTEMPT, payload: true });
				return Promise.reject(error);
			});
	};
}

export function logoutUser() {
	return function(dispatch) {
		localStorage.removeItem("accessToken");
		dispatch({ type: types.LOGOUT, payload: false });
		return Promise.resolve();
	};
}
