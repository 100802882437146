/* Updating Move Query functionality to apply to all items, currently copying
Duplicate Query to modal it after. */

import React from "react";
import { PropTypes, func } from "prop-types";
import { Modal, Button, Row, Form, Input, Radio, Select, notification, Icon } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const Option = Select.Option;

export class ShareFormModal extends React.Component {
	constructor(props) {
		super(props);

		this.updatedShare = null;
	}

	state = {
		visible: true,
		data: this.props.share.workbooks,
		team: [],
		value: [],
		workbooks: [],
		workbook: false,
		query: false,
		option: "workbook"
	};
	componentWillReceiveProps() {}
	teamChange = (data) => {
		this.setState({ team: data });
	};

	workbookChange = (data) => {
		this.setState({ workbooks: data });
	};

	radioChange = (data) => {
		if (data === "workbook") {
			this.setState({ workbook: true, option: data, query: false });
		} else if (data === "query") {
			this.setState({ workbook: false, option: data, query: true });
		}
	};

	openNotification = () => {
		notification.open({
			message: "Workbook Shared!",
			description: `Your workbook has been shared with selected team members successfully!`,
			icon: <Icon type="smile" style={{ color: "green" }} />
		});
	};

	handleSave() {
		this.openNotification();
		this.props.onSave(this.state.team, this.state.workbooks);
	}

	searchMember = (value) => {};

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0
				},
				sm: {
					span: 16,
					offset: 8
				}
			}
		};
		return (
			<Row>
				<Modal
					title={"Share Workbooks with your team."}
					visible={this.props.share.status}
					onCancel={() => this.props.onCancel()}
					footer={[
						<Button
							key="Share"
							disabled={this.state.workbooks.length > 0 && this.state.team.length > 0 ? false : true}
							onClick={() => this.handleSave()}
						>
							Share
						</Button>
					]}
				>
					<Form>
						<FormItem {...formItemLayout}>
							<Row>
								<label>Select Team Member(s): </label>
								<Select
									mode="multiple"
									placeholder="Select Team Members"
									onSearch={this.searchMember}
									onChange={this.teamChange}
								>
									{this.props.team ? (
										this.props.team.map((d, index) => (
											<Select.Option key={index}>{d.email}</Select.Option>
										))
									) : null}
								</Select>
							</Row>
							{this.state.option ? (
								<Row style={{ marginTop: "1rem" }}>
									<label>Select Workbook(s):</label>
									<Select
										mode="multiple"
										labelInValue
										placeholder="Select a workbook"
										onChange={this.workbookChange}
									>
										{this.props.workbooks ? (
											this.props.workbooks.map((d, index) => (
												<Select.Option key={index} description={d.description}>
													{d.name}
												</Select.Option>
											))
										) : null}
									</Select>
								</Row>
							) : null}
						</FormItem>
					</Form>
				</Modal>
			</Row>
		);
	}
}

ShareFormModal.propTypes = {
	share: PropTypes.object,
	onSave: func.isRequired,
	onCancel: func.isRequired
};

const ShareForm = Form.create()(ShareFormModal);

export default ShareForm;
