/* Updating Move Query functionality to apply to all items, currently copying
Duplicate Query to modal it after. */

import React from "react";
import { PropTypes, func } from "prop-types";
import { Modal, Button, Row, Form, Input, Radio, Select, notification, Icon } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const Option = Select.Option;

export class MoveFormModal extends React.Component {
	constructor(props) {
		super(props);

		this.updatedMove = null;
	}

	state = {
		alert: false,
		name: null,
		status: false,
		selected: false,
		checked: false,
		radioOption: null,
		workbook: null,
		name: null,
		desc: null,
		queries: [],
		copyQueries: [],
		value: 1
	};

	handleChange = (event) => {
		if (event.target.value.length > 0) {
			this.setState({ name: event.target.value });
			this.updatedMove[event.target.name] = event.target.value;
		} else {
			this.setState({ name: null });
		}
	};
	componentWillReceiveProps() {}
	onRadioSelect = (e) => {
		this.setState({
			value: e.target.value,
			selected: true,
			radioOption: e.target.value
		});
	};

	openNotification = () => {
		notification.open({
			message: "Query Moved Success",
			description: `You have successfully moved or copied a query`,
			icon: <Icon type="smile" style={{ color: "green" }} />
		});
	};

	onCheckSelect = (value, options) => {
		const arr = [];
		options.map((item) => {
			arr.push(item["props"]);
			return arr;
		});

		this.setState({ queries: value, copyQueries: arr });
	};

	CreateQueryOptions(workbook) {
		let queries = workbook.queries;
		const children = [];
		queries.forEach((item, index) => {
			children.push(
				<Option key={item.id} id={item.id} name={item.name} description={item.description} props={item}>
					{item.name}
				</Option>
			);
		});
		if (queries.length > 0) {
			return (
				<Row>
					<Row>Select the queries you would like to {this.state.radioOption}.</Row>
					<Row>
						<Select
							mode="tags"
							style={{ width: "100%" }}
							allowClear={true}
							maxTagCount={3}
							onChange={this.onCheckSelect}
							tokenSeparators={[ "," ]}
						>
							{children}
						</Select>
					</Row>
				</Row>
			);
		}
	}

	onWorkbookSelect = (value) => {
		this.setState({ workbook: value });
	};

	CreateWorkbookOptions(workbook, data) {
		const children = [];
		data.forEach((item, index) => {
			children.push(<Option key={item.id}>{item.name}</Option>);
		});
		return (
			<Row>
				<Row>Select a workbook</Row>
				<Row>
					<Select onChange={this.onWorkbookSelect}>{children}</Select>
				</Row>
			</Row>
		);
	}

	handleSave(query, id, workbook, value, copy) {
		this.openNotification();
		this.props.onSave(query, id, workbook, value, copy);
	}

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0
				},
				sm: {
					span: 16,
					offset: 8
				}
			}
		};
		return (
			<Row>
				{this.props.move.workbook && this.props.move.status === true ? (
					<Modal
						title={
							this.props.move.template === true ? (
								"Create a query from a template"
							) : (
								"Move or Copy Queries to another workbook."
							)
						}
						visible={this.props.move != null}
						onCancel={() => this.props.onCancel()}
						footer={[
							<Button key="back" onClick={() => this.props.onCancel()}>
								Cancel
							</Button>,
							<Button
								disabled={this.state.workbook ? false : true}
								key="save"
								id="primary__btn"
								disabled={this.state["workbook"] === null ? true : false}
								style={{ color: "white" }}
								onClick={() =>
									this.handleSave(
										this.state.queries,
										this.props.move.workbook.id,
										this.state.workbook,
										this.state.value,
										this.state.copyQueries
									)}
							>
								Save
							</Button>
						]}
					>
						<Form>
							<FormItem {...formItemLayout}>
								<Row>
									<Row>
										{this.props.move.template === true ? (
											<span>Would you like to copy a template to a query?</span>
										) : (
											<span>Would you like to copy or move queries?</span>
										)}
									</Row>
									<Row>
										<RadioGroup onChange={this.onRadioSelect} value={this.state.value}>
											{this.props.move.template !== true ? (
												<Radio value="move">Move</Radio>
											) : null}
											<Radio value="copy">Copy</Radio>
										</RadioGroup>
									</Row>
									{this.state.selected ? this.CreateQueryOptions(this.props.move.workbook) : null}
									{this.state.queries.length > 0 ? (
										this.CreateWorkbookOptions(this.props.move.workbook, this.props.move.data)
									) : null}
								</Row>
							</FormItem>
						</Form>
					</Modal>
				) : null}
			</Row>
		);
	}
}

MoveFormModal.propTypes = {
	move: PropTypes.object,
	onSave: func.isRequired,
	onCancel: func.isRequired
};

const MoveForm = Form.create()(MoveFormModal);

export default MoveForm;
