import React from "react";
import { Grid, Row, Col } from "react-bootstrap";

export class TermsContainer extends React.Component {
	componentDidMount() {
		window.analytics.page("Terms Page");
	}
	render() {
		return (
			<div className="terms-page">
				<Grid>
					<Row>
						<Col xs={10} xsOffset={1} className="terms-box">
							<h3>Terms of Service</h3>
							Coming Soon
						</Col>
					</Row>
				</Grid>
			</div>
		);
	}
}

export default TermsContainer;
