import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { PREVIEW_COLUMNS } from "../constants/previewConstants";

let previewTable = (previewResults) => {
	return PREVIEW_COLUMNS.map((metric) => {
		return (
			<tr key={metric.display}>
				<td className="metric-name">{metric.display}</td>
				<td className="metric-value">{previewResults[metric.key]}</td>
			</tr>
		);
	});
};

const QueryPreview = (props) => (
	<Table className="query-preview" condensed>
		<tbody>{previewTable(props.previewResults)}</tbody>
	</Table>
);

QueryPreview.propTypes = {
	previewResults: PropTypes.object.isRequired
};

export default QueryPreview;
