/* Updating Move Query functionality to apply to all items, currently copying
Duplicate Query to modal it after. */

import React from "react";
import { PropTypes, func } from "prop-types";
import { Modal, Button, Row, Form, Input, Radio, Select } from "antd";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const Option = Select.Option;

export class ShareQueryModal extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		workbook: this.props.share.workbook,
		query: this.props.share.query,
		team: [],
		data: []
	};

	componentWillReceiveProps() {}

	teamChange = (data) => {
		this.setState({ team: data });
	};

	searchMember = (value) => {
		// console.log(value, this.state.data);
		// const str = value.toLowerCase();
		// const arr = [];
		// if (str !== "") {
		// 	this.props.team.forEach((item, i) => {
		// 		const check = item.email.toLowerCase().includes(str);
		// 		console.log(item.email, str, check);
		// 		if (check === true) {
		// 			arr.push(item);
		// 			this.setState({ data: arr });
		// 		}
		// 	});
		// } else {
		// 	this.setState({ data: this.props.team });
		// }
	};

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0
				},
				sm: {
					span: 16,
					offset: 8
				}
			}
		};
		return (
			<Row>
				<Modal
					title={"Share Queries with your team."}
					visible={this.props.share.status}
					onCancel={() => this.props.onCancel(false, this.props.share.workbook, this.props.share.query)}
					footer={[
						<Button
							key="Share"
							disabled={false}
							onClick={() =>
								this.props.onSave(this.props.share.workbook, this.props.share.query, this.state.team)}
						>
							Share
						</Button>
					]}
				>
					<Form>
						<FormItem {...formItemLayout}>
							<Row>
								<label>Select team members you'd like to share with:</label>
								<Select
									mode="multiple"
									placeholder="Select Team Members"
									onSearch={this.searchMember}
									onChange={this.teamChange}
								>
									{this.props.team ? (
										this.props.team.map((d) => (
											<Select.Option key={d.extId}>{d.email}</Select.Option>
										))
									) : null}
								</Select>
							</Row>
						</FormItem>
					</Form>
				</Modal>
			</Row>
		);
	}
}

ShareQueryModal.propTypes = {
	share: PropTypes.object,
	onSave: func.isRequired,
	onCancel: func.isRequired
};

const ShareQueryForm = Form.create()(ShareQueryModal);

export default ShareQueryForm;
