import {
  GET_SEARCH,
  ENRICH_SUCCESS,
  ENRICH_FAILED,
  ENRICH_PENDING
} from "../constants/actionTypes";
import initialState from "./initialState";

export default function searchReducer(
  state = initialState.searchResults,
  action
) {
  switch (action.type) {
    case GET_SEARCH:
      return Object.assign({}, state, {
        resultSetId: action.payload.resultSetId,
        results: action.payload.results,
        stats: action.payload.stats,
        status: false,
        error: false
      });

    case ENRICH_SUCCESS:
      return Object.assign({}, state, {
        resultSetId: action.payload.resultSetId,
        results: action.payload.results,
        stats: action.payload.stats,
        status: action.status,
        error: action.error
      });

    case ENRICH_PENDING:
      return Object.assign({}, state, {
        status: action.status
      });

    case ENRICH_FAILED:
      return Object.assign({}, state, {
        status: action.status,
        error: action.error
      });

    default:
      return state;
  }
}
