import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function getUser() {
	return function(dispatch) {
		return axios.get(api.getUser(), apiUtils.getConfig()).then((res) => {
			return axios.get(api.getTeam(), apiUtils.getConfig()).then((team) => {
				dispatch({
					type: types.SET_USER,
					payload: res.data,
					team: team.data
				});

				return Promise.resolve(res.data);
			});
		});
	};
}

export function getGreenhouseKey(name, email, key, backfill, onBehalf) {
	return function(dispatch) {
		let body = {
			name: name,
			atsAdapterType: "ghio",
			atsApiKey: key,
			atsOnBehalfOf: onBehalf,
			clientContactEmails: email,
			twgContactEmails: "support@thiswayglobal.com",
			performBackFill: backfill
		};

		return axios
			.post(api.getGreenhouse(), body, apiUtils.getConfig())
			.then((res) => {
				dispatch({
					type: types.GET_GHKEY,
					payload: res.data
				});

				return Promise.resolve(res.data);
			})
			.catch((e) => e);
	};
}

export function getGreenhouseClients() {
	return function(dispatch) {
		return axios.get(api.getGHClients(), apiUtils.getConfig()).then((res) => {
			dispatch({
				type: types.GET_GHCLIENTS,
				payload: res.data
			});
			return Promise.resolve();
		});
	};
}

export function getSingleGreenhouseClient(id) {
	return function(dispatch) {
		return axios.get(api.getGHClientByKey(id), apiUtils.getConfig()).then((res) => {
			dispatch({ type: types.GET_GHKEY, payload: res.data });
			return Promise.resolve();
		});
	};
}

export function getGreenhouseOptions(id) {
	return function(dispatch) {
		return axios.get(api.AllGHClientOptions(id), apiUtils.getConfig()).then((res) => {
			dispatch({
				type: types.GET_GHOPTIONS,
				payload: res.data
			});
			return Promise.resolve();
		});
	};
}

export function createGreenhouseOption(data) {
	const { key, name, value } = data;

	let createValues = {
		name: name,
		value: value
	};
	return function(dispatch) {
		return axios.post(api.GHClientOption(key, name), createValues, apiUtils.getConfig()).then((res) => {
			dispatch({
				type: types.CREATE_GHOPTIONS,
				payload: res.data
			});

			const data = {
				clientKey: key,
				optionName: name,
				value: value
			};

			return Promise.resolve();
		});
	};
}

export function updateGreenhouseOption(data) {
	const { clientKey, optionName, value } = data;
	return function(dispatch) {
		return axios.put(api.GHClientOption(clientKey, optionName), data, apiUtils.getConfig()).then((res) => {
			dispatch({
				type: types.UPDATE_GHOPTIONS,
				payload: res.data
			});
			return Promise.resolve();
		});
	};
}

export function updateGreenhouseClient(update, data) {
	return function(dispatch) {
		return axios
			.put(api.updateGHClient(data), update, apiUtils.getConfig())
			.then((res) => {
				dispatch({
					type: types.UPDATE_GHCLIENT,
					payload: res.data
				});
				console.log(res.data);
			})
			.catch((e) => console.log(e));
	};
}

export function deleteGreenhouseOption(key, name) {
	return function(dispatch) {
		return axios.delete(api.GHClientOption(key, name), apiUtils.getConfig()).then((res) => {
			dispatch({
				type: types.UPDATE_GHOPTIONS,
				payload: res.data
			});
			return Promise.resolve();
		});
	};
}

export function clearUser() {
	return function(dispatch) {
		return dispatch({
			type: types.CLEAR_USER
		});
	};
}

export function getTeam() {
	return function(dispatch) {
		return axios.get(api.getTeam(), apiUtils.getConfig()).then((res) => {
			dispatch({
				type: types.SET_TEAM,
				padload: res.data
			});
			return Promise.resolve();
		});
	};
}
