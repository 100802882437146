import React, { Component } from "react";
import { Layout } from "antd";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

const { Footer } = Layout;

class FooterBar extends Component {
	render() {
		const { pathname } = this.props.location;

		return (
			<Footer
				className="footer"
				style={{
					textAlign: "center",
					position: "absolute",
					bottom: 0,
					padding: "20px 40px",
					background: "#277796",
					color: "white",
					width: "100%"
				}}
			>
				AI4JOBS © Copyright 2018 ThisWayGlobal
			</Footer>
		);
	}
}

FooterBar.propTypes = {
	history: ReactRouterPropTypes.history.isRequired
};

export default withRouter(connect(null, null)(FooterBar));
