import { combineReducers } from "redux";
import session from "./sessionReducer";
import user from "./userReducer";
import workbooks from "./workbooksReducer";
import query from "./queryReducer";
import queryLayout from "./queryLayoutReducer";
import forms from "./formsReducer";
import searchResults from "./searchReducer";
import previewResults from "./previewReducer";
import passwordLink from "./passwordLinkReducer";
import passwordReset from "./passwordResetReducer";
import queryTester from "./queryTesterReducer";
import greenHouse from "./greenhouseReducer";
import alerts from "./alertsReducer";
import options from "./optionsReducer";
import { routerReducer } from "react-router-redux";

const rootReducer = combineReducers({
	session,
	user,
	workbooks,
	query,
	queryLayout,
	forms,
	queryTester,
	searchResults,
	previewResults,
	passwordLink,
	passwordReset,
	alerts,
	greenHouse,
	options,
	routing: routerReducer
});

export default rootReducer;
