import React, { Component } from "react";
import { Row, Col, Divider, Affix } from "antd";
import n from "numeral";

const statBoxStyle = {
	minHeight: 110,
	width: "100%",
	background: "white",
	borderRadius: 5,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	marginBottom: "2rem"
};

const DividerStyle = {
	margin: "10px 0px 20px 0px"
};

const altBoxStyle = {
	minHeight: 100,
	width: "100%",
	padding: "15px",
	background: "white",
	marginBottom: "2rem",
	borderRadius: 5
};

class QueryStats extends Component {
	getRelevanceRating(value) {
		if (value < 10) {
			return <span className="stat__rating--red">Not Relevant</span>;
		} else if (value > 10 && value < 20) {
			return <span className="stat__rating--yellow">Relevant</span>;
		} else if (value > 20 && value < 50) {
			return <span className="stat__rating--orange">Very Relevant</span>;
		} else if (value > 50) {
			return <span className="stat__rating--green">Extremely Relevant</span>;
		}
	}

	getQueryRating(value) {
		if (value < 10) {
			return <span style={{ color: "red" }}>Poor</span>;
		} else if (value > 10 && value < 20) {
			return <span style={{ color: "darkyellow" }}>Moderate</span>;
		} else if (value > 20 && value < 50) {
			return <span style={{ color: "orange" }}>Strong</span>;
		} else if (value > 50) {
			return <span style={{ color: "green" }}>Excellent</span>;
		}
	}

	getDifRating(max, mean) {
		const value = max - mean;

		if (value > mean) {
			return <span>Broad</span>;
		} else if (value < mean) {
			return <span>Targeted</span>;
		}
	}

	getResultsRating() {}

	getRemainder(a, b, c, d, total, label) {
		if (label === "c") {
			if (a >= 200) {
				return 0;
			} else if (b >= 200) {
				return 0;
			} else if (b <= 200 && c !== 0 && c <= 200) {
				return c - b;
			} else if (c === 0) {
				return 0;
			} else if (b <= 200 && c >= 200) {
				return 200 - b;
			} else {
				return 200;
			}
		}
		if (label === "d") {
			if (a >= 200) {
				return 0;
			} else if (c === 0 && total >= 200) {
				return 200;
			} else if (c <= 200) {
				if (total >= 200) {
					return 200 - c;
				} else {
					return total - c;
				}
			} else if (c > 200) {
				return 0;
			}
		}

		if (label === "b") {
			if (a > 200) {
				return 0;
			} else if (b >= 200) {
				return 200 - a;
			} else {
				return b - a;
			}
		}

		if (label === "a") {
			if (a >= 200) {
				return 200;
			} else {
				return a;
			}
		}
	}

	compareTotal(total) {
		if (total < 200) {
			return total;
		} else if (total > 200) {
			return 200;
		}
	}

	getAboveMean(value) {
		if (value >= 200) {
			return `${n(1).format("0%")}`;
		} else if (value < 200) {
			return `${n(value / 200).format("0%")}`;
		}
	}

	getResultSize(value) {
		if (value > 200) {
			return 200;
		} else if (value < 200) {
			return `${value} of 200`;
		}
	}

	render() {
		const preview = this.props.preview;

		const a = preview.oneStdDevBelowMaxCount;
		const b = preview.twoStdDevBelowMaxCount;
		const c = preview.threeStdDevBelowMaxCount;
		const d = preview.fiveStdDevBelowMaxCount;
		const max = preview.maxRelevancy;
		const mean = preview.meanRelevancy;
		const total = preview.hits;
		return (
			<Col
				span={4}
				style={{
					padding: "10px 2px",
					display: "flex",
					flexDirection: "column",
					flex: 1,
					flexGrow: 1,
					marginLeft: "2rem"
				}}
			>
				<Row style={statBoxStyle}>
					<Row style={{ fontSize: "2rem" }}>{this.getRelevanceRating(preview.meanRelevancy, preview)}</Row>
					<Row>Search Rating</Row>
				</Row>
				<Row style={statBoxStyle}>
					<Row style={{ fontSize: "3rem" }}>{this.getResultSize(preview.hits)}</Row>
					<Row>Top Candidates Found</Row>
				</Row>

				<Row style={altBoxStyle}>
					<Row style={{ marginTop: "1rem", textAlign: "center", fontWeight: "bold", marginBottom: "2rem" }}>
						<Col>
							<h5>Candidate Groups</h5>
						</Col>
					</Row>
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Very Qualified:</Col>
						<Col style={{ float: "right" }}>
							{this.getRemainder(a, b, c, d, total, "a")} ({n(
								this.getRemainder(a, b, c, d, total, "a") / this.compareTotal(total)
							).format("0%")})
						</Col>
					</Row>
					<Divider style={DividerStyle} />
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Qualified:</Col>
						<Col style={{ float: "right" }}>
							{this.getRemainder(a, b, c, d, total, "b")} ({n(
								this.getRemainder(a, b, c, d, total, "b") / this.compareTotal(total)
							).format("0%")})
						</Col>
					</Row>
					<Divider style={DividerStyle} />
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Semi Qualified:</Col>
						<Col style={{ float: "right" }}>
							{this.getRemainder(a, b, c, d, total, "c")} ({n(
								this.getRemainder(a, b, c, d, total, "c") / this.compareTotal(total)
							).format("0%")})
						</Col>
					</Row>
					<Divider style={DividerStyle} />
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Least Qualified:</Col>
						<Col style={{ float: "right" }}>
							{this.getRemainder(a, b, c, d, total, "d")} ({n(
								this.getRemainder(a, b, c, d, total, "d") / this.compareTotal(total)
							).format("0%")})
						</Col>
					</Row>
				</Row>
				<Row style={statBoxStyle}>
					<Row style={{ fontSize: "3rem" }}>{n(preview.hits).format("0,0")}</Row>
					<Row>Potential Candidates</Row>
				</Row>
				{/* <Row style={altBoxStyle}>
					<Row style={{ marginTop: "1rem", textAlign: "center", fontWeight: "bold", marginBottom: "2rem" }}>
						<Col>
							<h5>Relevancy Scores</h5>
						</Col>
					</Row>
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Top Candidate:</Col>
						<Col style={{ float: "right" }}>{preview.maxRelevancy}</Col>
					</Row>
					<Divider style={{ margin: "10px 0px" }} />
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Mean:</Col>
						<Col style={{ float: "right" }}>{preview.meanRelevancy}</Col>
					</Row>
					<Divider style={{ margin: "5px 0px" }} />
					<Row style={{ marginTop: "1rem" }}>
						<Col style={{ float: "left" }}>Potential:</Col>
						<Col style={{ float: "right" }}>{n(preview.hits).format("0,0")}</Col>
					</Row>
				</Row> */}
			</Col>
		);
	}
}

export default QueryStats;
