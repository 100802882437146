import initialState from './initialState';
import { RESET_PASSWORD, RESET_PASSWORD_COMPLETE, SET_RESET_PASSWORD_ERROR} from '../constants/actionTypes';

export default function passwordResetReducer(state = initialState.passwordReset, action) {
  switch (action.type) {
    case RESET_PASSWORD:
      return {...state, isLoading: true, hasError: false, message: ''};

    case RESET_PASSWORD_COMPLETE:
      return {...state, 
        isLoading: false,
        hasError: action.payload.hasError,
        message: action.payload.message,
        resetSuccess: !action.payload.hasError
      };
    
    case SET_RESET_PASSWORD_ERROR:
      return {...state, 
        isLoading: false,
        hasError: action.payload.hasError,
        message: action.payload.message,
        resetSuccess: false
      };

    default:
      return state;
  }
}
