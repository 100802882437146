import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";

export function getQuery(workbookId, queryId) {
	return function(dispatch) {
		axios
			.get(`${api.query(workbookId)}/${queryId}`, apiUtils.getConfig())
			.then((res) => {
				return dispatch({
					type: types.GET_QUERY,
					payload: res.data
				});
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function updateQuery(workbookId, query) {
	return function(dispatch) {
		dispatch({
			type: types.SET_SAVING,
			isSaving: true
		});
		return axios.put(`${api.query(workbookId)}/${query.id}`, query, apiUtils.getConfig()).catch((e) => e);
	};
}

export function previewQuery(workbookId, queryId) {
	return function(dispatch) {
		axios
			.get(api.getPreview(workbookId, queryId), apiUtils.getConfig())
			.then((res) => {
				dispatch({
					type: types.SET_SAVING,
					isSaving: false
				});

				return dispatch({
					type: types.GET_PREVIEW,
					payload: res.data
				});
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function addFilter(filterKey) {
	return function(dispatch) {
		dispatch({
			type: types.ADD_FILTER,
			filterKey
		});

		return Promise.resolve();
	};
}

export function removeFilter(filterKey) {
	return function(dispatch) {
		dispatch({
			type: types.REMOVE_FILTER,
			filterKey
		});

		return Promise.resolve();
	};
}

export function updateSubFilter(filterIndex, subFilterIndex, filterType, newValues) {
	return function(dispatch) {
		dispatch({
			type: types.UPDATE_SUB_FILTER,
			payload: {
				filterIndex: filterIndex,
				subFilterIndex: subFilterIndex,
				filterType: filterType,
				newValues: newValues
			}
		});

		return Promise.resolve();
	};
}
