import React from "react";
import { PropTypes, func } from "prop-types";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
import { QUERY_CATEGORIES } from "../constants/queryConstants";
import Ionicon from "react-ionicons";

export class QueryCategories extends React.Component {
  render() {
    let listItems = QUERY_CATEGORIES.filter(category => {
      return this.props.cards.indexOf(category.key) < 0;
    }).map(category => {
      let tooltip = (
        <Tooltip id="tooltip" className="tooltip">
          {category.displayName}
        </Tooltip>
      );

      return (
        <OverlayTrigger key={category.key} placement="right" overlay={tooltip}>
          <ListGroupItem
            href="#"
            onClick={() => this.props.onAddFilter(category.key)}
          >
            <span className="category-icon">
              <Ionicon icon={category.icon} />
            </span>

            {this.props.isCollapsed ? null : (
              <span>
                &nbsp;
                {category.displayName}
              </span>
            )}
          </ListGroupItem>
        </OverlayTrigger>
      );
    });

    return (
      <div className={"sidebar " + (this.props.isCollapsed ? "collapsed" : "")}>
        <Button
          bsStyle="success"
          className="collapse-btn"
          onClick={this.props.onToggleCollapse}
        >
          <Ionicon
            icon={
              this.props.isCollapsed
                ? "ios-arrow-dropright"
                : "ios-arrow-dropleft"
            }
          />
        </Button>

        <ListGroup>{listItems}</ListGroup>
      </div>
    );
  }
}

QueryCategories.propTypes = {
  cards: PropTypes.array.isRequired,
  onAddFilter: func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  onToggleCollapse: func.isRequired
};

export default QueryCategories;
