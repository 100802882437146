import * as types from "../constants/actionTypes";
import axios from "axios";
import * as api from "../constants/apiConstants";
import * as apiUtils from "../utils/apiUtils";
var fileDownload = require("js-file-download");

export function getSearch(workbookId, queryId) {
	return function(dispatch) {
		axios
			.get(api.getSearch(workbookId, queryId), apiUtils.getConfig())
			.then((res) => {
				return dispatch({
					type: types.GET_SEARCH,
					payload: res.data
				});
			})
			.catch((error) => {
				throw error;
			});
	};
}

export function enrichStatus() {
	return function(dispatch) {
		return dispatch({
			type: types.ENRICH_PENDING,
			status: true,
			error: false
		});
	};
}

export function enrichSearch(resultSetId, enrichedIds) {
	return function(dispatch) {
		let data = {
			resultSetId: resultSetId,
			resultIds: enrichedIds
		};

		axios
			.post(api.enrichSearch(), data, apiUtils.getConfig())
			.then((res) => {
				return dispatch({
					type: types.ENRICH_SUCCESS,
					payload: res.data,
					status: false,
					error: false
				});
			})
			.catch(() => {
				return dispatch({
					type: types.ENRICH_FAILED,
					status: true,
					error: true
				});
			});
	};
}

export function exportSearch(resultSetId, enrichedIds) {
	return function(dispatch) {
		let data = {
			resultSetId: resultSetId,
			resultIds: enrichedIds
		};

		axios
			.post(api.exportSearch(), data, apiUtils.getConfig())
			.then((res) => {
				if (res.status == 200) {
					fileDownload(res.data, "ai4jobs-export.csv");

					return dispatch({
						type: null
					});
				}
			})
			.catch((error) => {
				throw error;
			});
	};
}
