import {
  UPDATE_CURRENT_WORKBOOK,
  UPDATE_CURRENT_QUERY,
  UPDATE_DUPLICATE_MODAL,
  UPDATE_MOVE_MODAL,
  UPDATE_SHARE_MODAL,
  UPDATE_SHARE_QUERY_MODAL
} from "../constants/actionTypes";
import initialState from "./initialState";

export default function formsReducer(state = initialState.forms, action) {
  switch (action.type) {
    case UPDATE_CURRENT_WORKBOOK:
      return {
        ...state,
        currentWorkbook: action.workbook
      };

    case UPDATE_CURRENT_QUERY:
      return {
        ...state,
        currentQuery: action.query
      };

    case UPDATE_DUPLICATE_MODAL:
      return {
        ...state,
        duplicateModal: action.duplicateModal
      };
    case UPDATE_MOVE_MODAL:
      return {
        ...state,
        moveModal: action.moveModal
      };
    case UPDATE_SHARE_MODAL:
      return {
        ...state,
        shareModal: action.shareModal
      };
    case UPDATE_SHARE_QUERY_MODAL:
      return {
        ...state,
        shareQuery: action.shareQuery
      };
    default:
      return state;
  }
}
