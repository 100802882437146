import {GET_PREVIEW} from '../constants/actionTypes';
import initialState from './initialState';

export default function previewReducer(state = initialState.previewResults, action) {
  switch (action.type) {
    case GET_PREVIEW:
      return action.payload;

    default:
      return state;
  }
}
