import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/testActions";
import { Row, Col, Switch, Table, Icon, Divider, Button } from "antd";
import n from "numeral";
import ResultsDrawer from "../../ResultsDrawer2.js";
import sampleLogo from "../../../images/SampleLogo.png";
import Excellent from "../../../images/ExecellentRelevancy.svg";
import Good from "../../../images/GoodRelevancy.svg";
import Okay from "../../../images/OkayRelevancy.svg";
import Poor from "../../../images/PoorRelevancy.svg";
import faker from "faker";

const ZipRecruiter =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164747/ziprecruiter_color.png";
const ZipRecruiter_grey =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164747/ziprecruiter_grey.png";
const Linkedin =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164139/linkedin_color.png";
const Linkedin_grey =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01162843/LinkedIn_grey.png";
const Glassdoor =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164138/glassdoor_color.png";
const Glassdoor_grey =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01162842/glassdoor_grey.png";
const Angel =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164137/angelist_color.png";
const Angel_grey =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164125/angelist_gray.png";
const Indeed =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164139/indeed_color.png";
const Indeed_grey =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01162843/indeed_grey.png";
const Monster =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164137/monster_color.png";
const Monster_grey =
  "https://s3-us-east-2.amazonaws.com/www.thiswayglobal.com/wp-content/uploads/2019/11/01164127/monster_grey.png";

const imageStyle = {
  height: 22
};
class DemoResults extends Component {
  state = {
    results: [],
    modified: [],
    loading: true,
    selectedRowKeys: [],
    selectedItems: [],
    resultCount: 200,
    pageSize: 100,
    profile: null,
    expandedRowKeys: [],
    expandStatus: false,
    view: "table",
    export: false,
    profile: null,
    visible: false,
    profileIndex: null,
    group_1: [],
    group_2: [],
    group_3: [],
    group_4: [],
    activeGroups: [],
    redacted: true
  };

  componentWillMount() {
    this.setState(
      {
        results: this.props.data,
        modified: this.props.data
      },
      () => {
        this.createFilters();
      }
    );
  }

  createFilters() {
    const one = [];
    const two = [];
    const three = [];
    const four = [];
    const {
      oneStdDevBelowMaxCount,
      twoStdDevBelowMaxCount,
      threeStdDevBelowMaxCount,
      fiveStdDevBelowMaxCount,
      aboveMeanCount
    } = this.props.stats;

    const a = oneStdDevBelowMaxCount;
    const b = twoStdDevBelowMaxCount;
    const c = threeStdDevBelowMaxCount;
    const d = fiveStdDevBelowMaxCount;
    const total = aboveMeanCount;

    const group_1 = this.getRemainder(a, b, c, d, total, "a");
    const group_2 = this.getRemainder(a, b, c, d, total, "b");
    const group_3 = this.getRemainder(a, b, c, d, total, "c");
    const group_4 = this.getRemainder(a, b, c, d, total, "d");

    this.state.results.forEach((item, index) => {
      if (one.length < group_1) {
        one.push(item);
      } else if (two.length < group_2 - group_1) {
        two.push(item);
      } else if (three.length < group_3 - group_2) {
        three.push(item);
      } else if (four.length < group_4 - group_3) {
        four.push(item);
      }
    });

    this.setState(
      { group_1: one, group_2: two, group_3: three, group_4: four },
      () => {
        console.log("Filters Created");
      }
    );
  }

  createProfile(data) {
    this.setState(
      { profile: data, profileIndex: this.state.modified.indexOf(data) },
      () => {
        if (this.state.visible === true) {
          this.setState({ visible: false });
        } else {
          this.setState({ visible: true });
        }
      }
    );
  }

  onClose() {
    if (this.state.visible === true) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }
  }

  onProfileSelect(id) {
    const index = this.state.selectedRowKeys.indexOf(id);
    const selected = this.state.selectedRowKeys;
    if (index === -1) {
      selected.push(id);
      this.setState({ selectedRowKeys: selected });
    } else if (index > -1) {
      selected.splice(index, 1);
      this.setState({ selectedRowKeys: selected });
    }
  }

  switchProfile(tag) {
    if (tag === "next") {
      const index = this.state.results.indexOf(this.state.profile);
      if (index !== this.state.results.length - 1) {
        this.setState({
          profile: this.state.results[index + 1]
        });
      }
    } else if (tag === "prev") {
      const index = this.state.results.indexOf(this.state.profile);
      if (index !== 0) {
        this.setState({
          profile: this.state.results[index - 1]
        });
      }
    }
  }

  checkRelevancy(data) {
    const { group_1, group_2, group_3, group_4 } = this.state;
    const a = group_1.indexOf(data);
    const b = group_2.indexOf(data);
    const c = group_3.indexOf(data);
    const d = group_4.indexOf(data);
    if (a > -1) {
      return <img src={Excellent} />;
    } else if (b > -1) {
      return <img src={Good} />;
    } else if (c > -1) {
      return <img src={Okay} />;
    } else if (d > -1) {
      return <img src={Poor} />;
    }
  }

  onRedacted(checked) {
    this.setState({ redacted: checked });
  }

  checkStatus() {
    const random = Math.floor(Math.random() * 2) + 1;

    if (random === 1) {
      return <span>Direct Apply</span>;
    } else if (random === 2) {
      return <span>Indirect Apply</span>;
    }
  }
  checkSource(index) {
    const Check = this.state.redacted;
    const random = Math.floor(Math.random() * 6) + 1;
    if (index <= 4) {
      if (random === 1) {
        return (
          <img
            src={Check === false ? Indeed : Indeed_grey}
            style={imageStyle}
          />
        );
      } else if (random === 2) {
        return (
          <img
            src={Check === false ? Linkedin : Linkedin_grey}
            style={imageStyle}
          />
        );
      } else if (random === 3) {
        return (
          <img
            src={Check === false ? ZipRecruiter : ZipRecruiter_grey}
            style={imageStyle}
          />
        );
      } else if (random === 4) {
        return (
          <img
            src={Check === false ? Glassdoor : Glassdoor_grey}
            style={imageStyle}
          />
        );
      } else if (random === 5) {
        return (
          <img
            src={Check === false ? Monster : Monster_grey}
            style={imageStyle}
          />
        );
      } else if (random === 6) {
        return (
          <img src={Check === false ? Angel : Angel_grey} style={imageStyle} />
        );
      }
    } else {
      return (
        <span style={{ padding: "5px 20px", border: "1px solid #f7f7f7" }}>
          Premium Required
        </span>
      );
    }
  }

  checkMatch(data) {
    const { group_1, group_2, group_3, group_4 } = this.state;
    const a = group_1.indexOf(data);
    const b = group_2.indexOf(data);
    const c = group_3.indexOf(data);
    const d = group_4.indexOf(data);
    if (a > -1) {
      return (
        <span
          style={{
            padding: "5px 20px",
            borderRadius: 2,
            border: "1px solid rgba(27, 163, 79, 1)",
            background: "rgba(27, 163, 79, .25)",
            color: "rgba(27, 163, 79, 1)",
            fontWeight: "bold"
          }}
        >
          A
        </span>
      );
    } else if (b > -1) {
      return (
        <span
          style={{
            padding: "5px 20px",
            borderRadius: 2,
            border: "1px solid rgba(0, 116, 146, 1)",
            background: "rgba(0, 116, 146, .25)",
            color: "rgba(0, 116, 146, 1)",
            fontWeight: "bold"
          }}
        >
          B
        </span>
      );
    } else if (c > -1) {
      return (
        <span
          style={{
            padding: "5px 20px",
            borderRadius: 2,
            border: "1px solid rgba(245, 170, 39, 1)",
            background: "rgba(245, 170, 39, .25)",
            color: "rgba(245, 170, 39, 1)",
            fontWeight: "bold"
          }}
        >
          C
        </span>
      );
    } else if (d > -1) {
      return (
        <span
          style={{
            padding: "5px 20px",
            borderRadius: 2,
            border: "1px solid rgba(148, 58, 110, 1)",
            background: "rgba(148, 58, 110, .25)",
            color: "rgba(148, 58, 110, 1)",
            fontWeight: "bold"
          }}
        >
          D
        </span>
      );
    }
  }

  onGroupClick(label) {
    const arr = [];
    const index = this.state.activeGroups.indexOf(label);
    if (index === -1) {
      arr.push(label);

      if (label === 1) {
        this.setState({ activeGroups: arr, modified: this.state.group_1 });
      } else if (label === 2) {
        this.setState({ activeGroups: arr, modified: this.state.group_2 });
      } else if (label === 3) {
        this.setState({ activeGroups: arr, modified: this.state.group_3 });
      } else if (label === 4 && arr.indexOf(label) > -1) {
        this.setState({ activeGroups: arr, modified: this.state.group_4 });
      }
    } else if (index === 0) {
      // Remove label
      this.setState({ modified: this.state.results, activeGroups: [] });
    }

    // Update Active Groups
  }

  onColorChange(label) {
    const arr = this.state.activeGroups;
    const index = arr.indexOf(label);
    if (index === -1) {
      return "#404040";
    } else {
      return "white";
    }
  }
  onIconColorChange(label) {
    const arr = this.state.activeGroups;
    const index = arr.indexOf(label);

    if (index === -1) {
      if (label === 1) {
        return "rgba(27, 163, 79, 1)";
      } else if (label === 2) {
        return "rgba(0, 116, 146, 1)";
      } else if (label === 3) {
        return "rgba(245, 170, 39, 1)";
      } else if (label === 4) {
        return "rgba(148, 58, 110, 1)";
      } else {
        return "#404040";
      }
    } else {
      return "white";
    }
  }
  onBackGroundColorChange(label) {
    const arr = this.state.activeGroups;
    const index = arr.indexOf(label);
    if (index === -1) {
      return "white";
    } else {
      return "#8bcbc3";
    }
  }

  onSelectChange = (selectedRowKeys, selected) => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedItems: selected
    });
  };

  getRemainder(a, b, c, d, total, label) {
    if (label === "c") {
      if (a >= 300) {
        return 0;
      } else if (b >= 300) {
        return 0;
      } else if (b <= 300 && c !== 0 && c <= 300) {
        return c - b;
      } else if (c === 0) {
        return 0;
      } else if (b <= 300 && c >= 300) {
        return 300 - b;
      } else {
        return 300;
      }
    }
    if (label === "d") {
      if (a >= 300) {
        return 0;
      } else if (c === 0 && total >= 300) {
        return 300;
      } else if (c <= 300) {
        if (total >= 300) {
          return 300 - c;
        } else {
          return total - c;
        }
      } else if (c > 300) {
        return 0;
      } else {
        return 300 - c;
      }
    }

    if (label === "b") {
      if (a > 300) {
        return 0;
      } else if (b >= 300) {
        return 300 - a;
      } else {
        return b - a;
      }
    }

    if (label === "a") {
      if (a >= 300) {
        return 300;
      } else {
        return a;
      }
    }
  }

  render() {
    const { selectedRowKeys, selectedItems, expandedRowKeys } = this.state;

    const columns = [
      {
        title: "Full Name",
        width: 200,
        key: "name",
        render: x => {
          const item = x.exportIndividual;

          return (
            <span
              style={{
                background:
                  this.state.redacted === true ? "#f7f7f7" : "transparent",
                color: this.state.redacted === true ? "#f7f7f7" : "#404040"
              }}
            >
              {faker.name.findName()}
            </span>
          );
        }
      },
      {
        title: "Match",
        key: "relevance",
        align: "center",
        width: 150,
        render: (x, r, i) => {
          const item = x.exportIndividual;
          return this.checkMatch(x);
        }
      },
      {
        title: "Status",
        align: "center",
        width: 150,
        render: x => {
          const item = x.exportIndividual;
          return this.checkStatus();
        }
      },
      {
        title: "Source",
        align: "center",
        width: 150,
        render: (x, r, i) => {
          const item = x.exportIndividual;
          return this.checkSource(i);
        }
      },
      {
        title: "Relevancy Score",
        align: "center",
        width: 150,
        render: (x, r, i) => {
          const item = x.exportIndividual;
          return this.checkRelevancy(x);
        }
      },

      {
        title: "",
        key: "",
        align: "center",
        width: 150,
        render: x => {
          const item = x.exportIndividual;
          return (
            <span
              style={{
                color: "#32b9ff",
                fontWeight: "bold",
                cursor: "pointer"
              }}
              onClick={() => this.createProfile(x)}
            >
              View Profile
            </span>
          );
        }
      }
    ];

    const { stats } = this.props;
    const name = "Search Name";
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const a = stats.oneStdDevBelowMaxCount;
    const b = stats.twoStdDevBelowMaxCount;
    const c = stats.threeStdDevBelowMaxCount;
    const d = stats.fiveStdDevBelowMaxCount;
    const total = stats.aboveMeanCount;

    return (
      <Row style={{ marginRight: "5.5rem", marginLeft: "5.5rem" }}>
        <Col span={24}>
          <Row style={{ marginBottom: "2rem" }} gutter={40}>
            <Col span={6}>
              <Col
                onClick={() => this.onGroupClick(1)}
                style={{
                  background: this.onBackGroundColorChange(1),
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  borderRadius: 4
                }}
              >
                <Row
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                  }}
                  type="flex"
                  align="middle"
                >
                  <Col span={4}>
                    <Icon
                      type="trophy"
                      style={{
                        fontSize: "3rem",
                        marginRight: "1rem",
                        float: "left",
                        marginTop: ".8rem",
                        color: this.onIconColorChange(1)
                      }}
                    />
                  </Col>
                  <Col
                    span={16}
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    <span
                      style={{
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: this.onColorChange(1)
                      }}
                    >
                      Group A
                    </span>
                  </Col>
                  <Col span={4}>
                    <Icon
                      type="info-circle"
                      theme="outlined"
                      style={{
                        float: "right",
                        color: this.onColorChange(1),
                        fontSize: "1.5rem",
                        marginTop: "1rem"
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <span
                    style={{
                      fontSize: "3rem",
                      color: this.onColorChange(1),
                      fontWeight: "bold"
                    }}
                  >
                    {this.state.group_1.length}
                  </span>
                </Row>
                <Divider style={{ marginTop: "1rem", marginBottom: ".5rem" }} />
                <Row style={{ padding: 5 }}>
                  <span
                    style={{
                      fontSize: "1.25rem",
                      color: this.onColorChange(1)
                    }}
                  >
                    {this.state.activeGroups.indexOf(1) > -1
                      ? "Click to deselect"
                      : "Click to view"}
                  </span>
                </Row>
              </Col>
            </Col>
            <Col span={6}>
              <Col
                onClick={() => this.onGroupClick(2)}
                style={{
                  background: this.onBackGroundColorChange(2),
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  borderRadius: 4
                }}
              >
                <Row
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                  }}
                  type="flex"
                  align="middle"
                >
                  <Col span={4}>
                    <Icon
                      type="trophy"
                      style={{
                        fontSize: "3rem",
                        marginRight: "1rem",
                        float: "left",
                        marginTop: ".8rem",
                        color: this.onIconColorChange(2)
                      }}
                    />
                  </Col>
                  <Col
                    span={16}
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    <span
                      style={{
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: this.onColorChange(2)
                      }}
                    >
                      Group B
                    </span>
                  </Col>
                  <Col span={4}>
                    <Icon
                      type="info-circle"
                      theme="outlined"
                      style={{
                        float: "right",
                        color: this.onColorChange(2),
                        fontSize: "1.5rem",
                        marginTop: "1rem"
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <span
                    style={{
                      fontSize: "3rem",
                      color: this.onColorChange(2),
                      fontWeight: "bold"
                    }}
                  >
                    {this.state.group_2.length}
                  </span>
                </Row>
                <Divider style={{ marginTop: "1rem", marginBottom: ".5rem" }} />
                <Row style={{ padding: 5 }}>
                  <span
                    style={{
                      fontSize: "1.25rem",
                      color: this.onColorChange(2)
                    }}
                  >
                    {this.state.activeGroups.indexOf(2) > -1
                      ? "Click to deselect"
                      : "Click to view"}
                  </span>
                </Row>
              </Col>
            </Col>
            <Col span={6}>
              <Col
                onClick={() => this.onGroupClick(3)}
                style={{
                  background: this.onBackGroundColorChange(3),
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  borderRadius: 4
                }}
              >
                <Row
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                  }}
                  type="flex"
                  align="middle"
                >
                  <Col span={4}>
                    <Icon
                      type="trophy"
                      style={{
                        fontSize: "3rem",
                        marginRight: "1rem",
                        float: "left",
                        marginTop: ".8rem",
                        color: this.onIconColorChange(3)
                      }}
                    />
                  </Col>
                  <Col
                    span={16}
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    <span
                      style={{
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: this.onColorChange(3)
                      }}
                    >
                      Group C
                    </span>
                  </Col>
                  <Col span={4}>
                    <Icon
                      type="info-circle"
                      theme="outlined"
                      style={{
                        float: "right",
                        color: this.onColorChange(3),
                        fontSize: "1.5rem",
                        marginTop: "1rem"
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <span
                    style={{
                      fontSize: "3rem",
                      color: this.onColorChange(3),
                      fontWeight: "bold"
                    }}
                  >
                    {this.state.group_3.length}
                  </span>
                </Row>
                <Divider style={{ marginTop: "1rem", marginBottom: ".5rem" }} />
                <Row style={{ padding: 5 }}>
                  <span
                    style={{
                      fontSize: "1.25rem",
                      color: this.onColorChange(3)
                    }}
                  >
                    {this.state.activeGroups.indexOf(3) > -1
                      ? "Click to deselect"
                      : "Click to view"}
                  </span>
                </Row>
              </Col>
            </Col>
            <Col span={6}>
              <Col
                onClick={() => this.onGroupClick(4)}
                style={{
                  background: this.onBackGroundColorChange(4),
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  borderRadius: 4
                }}
              >
                <Row
                  style={{
                    paddingTop: "1rem",
                    paddingBottom: "1rem"
                  }}
                  type="flex"
                  align="middle"
                >
                  <Col span={4}>
                    <Icon
                      type="trophy"
                      style={{
                        fontSize: "3rem",
                        marginRight: "1rem",
                        float: "left",
                        marginTop: ".8rem",
                        color: this.onIconColorChange(4)
                      }}
                    />
                  </Col>
                  <Col
                    span={16}
                    style={{ marginTop: "1rem", textAlign: "center" }}
                  >
                    <span
                      style={{
                        fontSize: "2rem",
                        fontWeight: "bold",
                        color: this.onColorChange(4)
                      }}
                    >
                      Group D
                    </span>
                  </Col>
                  <Col span={4}>
                    <Icon
                      type="info-circle"
                      theme="outlined"
                      style={{
                        float: "right",
                        color: this.onColorChange(4),
                        fontSize: "1.5rem",
                        marginTop: "1rem"
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                  <span
                    style={{
                      fontSize: "3rem",
                      color: this.onColorChange(4),
                      fontWeight: "bold"
                    }}
                  >
                    {this.state.group_4.length}
                  </span>
                </Row>
                <Divider style={{ marginTop: "1rem", marginBottom: ".5rem" }} />
                <Row style={{ padding: 5 }}>
                  <span
                    style={{
                      fontSize: "1.25rem",
                      color: this.onColorChange(4)
                    }}
                  >
                    {this.state.activeGroups.indexOf(4) > -1
                      ? "Click to deselect"
                      : "Click to view"}
                  </span>
                </Row>
              </Col>
            </Col>
          </Row>
          <Row style={{ background: "white", padding: 20 }}>
            <Row style={{ marginTop: "2rem" }}>
              <Col
                style={{
                  float: "left",
                  paddingLeft: "1rem",
                  paddingBottom: "1rem"
                }}
              >
                <span
                  style={{
                    fontSize: "3rem",
                    fontWeight: "bold",
                    lineHeight: "2rem"
                  }}
                >
                  Matching Results ({this.state.modified.length})
                </span>
              </Col>
              <Col
                style={{
                  float: "right",
                  paddingBottom: "1rem",
                  paddingLeft: "1rem",
                  cursor: "pointer"
                }}
              >
                <span
                  onClick={() => this.props.new()}
                  className="gabys__button"
                >
                  <Icon type="plus-square" /> New Query
                </span>
              </Col>
              <Col
                style={{
                  float: "right",
                  paddingBottom: "1rem",
                  paddingRight: "1rem"
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Relevancy <Icon type="caret-down" />
                </span>
              </Col>

              <Col
                style={{
                  float: "right",
                  paddingLeft: "5rem",
                  paddingRight: "5rem",
                  paddingBottom: "1rem"
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Match <Icon type="caret-down" />
                </span>
              </Col>
              <Col
                style={{
                  float: "right",
                  paddingBottom: "1rem"
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Source <Icon type="caret-down" />
                </span>
              </Col>
              <Col
                style={{
                  float: "right",
                  paddingLeft: "5rem",
                  paddingRight: "5rem",
                  paddingBottom: "1rem"
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Redactions{" "}
                  <Switch defaultChecked onChange={e => this.onRedacted(e)} />
                </span>
              </Col>
            </Row>
            <Divider style={{ marginTop: 0, marginBottom: "1rem" }} />
            <Table
              bordered={false}
              dataSource={this.state.modified}
              columns={columns}
              rowSelection={rowSelection}
              pagination={{
                pageSize: this.state.pageSize,
                position: "bottom",
                showSizeChanger: true,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ["25", "50", "75", "100"]
              }}
              rowKey={x => x.id}
              scroll={{ y: 450 }}
            />
          </Row>
          {this.state.profile !== null ? (
            <ResultsDrawer
              profile={this.state.profile}
              visible={this.state.visible}
              onClose={this.onClose.bind(this)}
              onMatch={this.checkMatch.bind(this)}
              onSelect={this.onProfileSelect.bind(this)}
              onRedacted={this.state.redacted}
              selectedRowKeys={this.state.selectedRowKeys}
              onSwitchProfile={this.switchProfile.bind(this)}
              name={faker.name.findName()}
              email={faker.internet.email()}
              phone={faker.phone.phoneNumber()}
              profileIndex={this.state.profileIndex}
              width={600}
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}

export default DemoResults;
