import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions/sessionActions";
import * as userActions from "../actions/userActions";
import { Image } from "react-bootstrap";
import { Icon, Avatar, Menu, Divider } from "antd";
import logo from "../images/ai4jobs-color-logo.png";
const { SubMenu } = Menu;

export class HeaderNavbar extends React.Component {
	state = {
		current: "mail"
	};

	handleClick = (e) => {
		this.setState({
			current: e.key
		});
	};

	componentDidMount() {
		if (this.props.isAuthenticated) {
			this.props.userActions.getUser().then(() => {
				let meta = {
					name: this.props.currentUser.fullName,
					email: this.props.currentUser.email
				};
				window.analytics.identify(meta);
			});
		}
	}

	onLogout = (event) => {
		this.props.actions.logoutUser().then(() => {
			this.props.userActions.clearUser();
		});
	};

	CreateIntials(name) {
		const splitName = name.split(" ");
		const intials = `${splitName[0].charAt(0)} ${splitName[1].charAt(0)}` || null;
		return intials;
	}

	render() {
		return this.props.isAuthenticated ? (
			<Menu
				onClick={this.handleClick}
				selectedKeys={[ this.state.current ]}
				mode="horizontal"
				style={{ paddingTop: 0, paddingLeft: 5, paddingRight: 0, boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.10)" }}
			>
				<Menu.Item>
					<img src={logo} style={{ width: 125 }} />
				</Menu.Item>
				<Menu.Item key="app">
					<a href="/workbooks">Workbooks</a>
				</Menu.Item>
				<Menu.Item
					style={{ float: "right", border: "1px solid #f3f3f3" }}
					eventKey={2.1}
					onClick={this.onLogout}
				>
					<Icon type="logout" /> Logout
				</Menu.Item>
				<Menu.Item style={{ float: "right" }}>
					<span
						style={{
							background: "gray",
							color: "white",
							marginRight: "1rem",
							borderRadius: 2,
							fontSize: "1.5rem",
							letterSpacing: "-1px",
							fontWeight: "bold",
							textAlign: "center",
							marginTop: "1rem"
						}}
					>
						<Icon type="user" style={{ marginLeft: 5, marginRight: 5, marginTop: 5 }} />
					</span>
					{this.props.currentUser.fullName}
				</Menu.Item>
				<Menu.Item style={{ float: "right" }}>
					<Icon type="bell" />
				</Menu.Item>
			</Menu>
		) : null;
	}
}

HeaderNavbar.propTypes = {
	actions: PropTypes.object.isRequired,
	userActions: PropTypes.object.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	currentUser: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		isAuthenticated: state.session.auth,
		currentUser: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		userActions: bindActionCreators(userActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ pure: false } // This fixes the "active" class state for nav bar links
)(HeaderNavbar);
