import React from "react";
import { PropTypes, func } from "prop-types";
import QueryPreview from "./QueryPreview";
import { Button } from "react-bootstrap";
import Ionicon from "react-ionicons";

export class QueryInformation extends React.Component {
	displayResponsiveInformation = () => {
		if (this.props.isCollapsed) {
			return (
				<div className="center">
					<Button bsStyle="primary" className="view-results-btn" onClick={this.props.onRunSearch}>
						<span className="icon">
							<Ionicon icon="ios-search-outline" />
						</span>
					</Button>
				</div>
			);
		} else {
			return (
				<div className="query-information" style={{}}>
					<div className="center">
						<QueryPreview previewResults={this.props.previewResults} />
					</div>
					{this.props.route.includes("template") === false ? (
						<div className="center">
							<Button bsStyle="success" className="view-results-btn" onClick={this.props.onRunSearch}>
								<span className="icon">
									<Ionicon icon="ios-search-outline" />
								</span>
								<span>View Results</span>
							</Button>
						</div>
					) : null}
					{/* <div className="job-description">
          <div>Finance Sector Workbook</div>
          <h4 className="query-name">Financial Advisor Search</h4>
          <p className="query-description">This search is for financial advisors in the Denver metro area.</p>
          <p className="job-description-label">Job Description:</p>
          <div>
            Financial Representatives with the SOME COMPANY provide expert guidance and innovative solutions for individuals and businesses in the areas of retirement solutions, insurance and investment services, estate analysis, business needs analysis, education funding and employee benefits.
            They strive to understand their clients goals and visions in order to uncover financial solutions that put them on a path to success. These Representatives are in business for themselves — but theyre not alone. Supported by our network of specialists, training programs and mentoring opportunities, they have access to the resources, products, and assistance they need to help their clients and build their practices.
            Skills/ Requirements:
            To be considered for the position, you should have:
            Bachelors degree from a four-year institution (not required, strongly preferred)
            Have strong interpersonal skills
            Be self-motivated and have a history of personal success.
            Benefits Program:
            SOME COMPANY is committed to offering a comprehensive benefit program including comprehensive medical coverage, retirement planning, flex Programs, group life and accidental death benefit, and disability income insurance.
            Company Accolades:
            World’s Most Admired Life Insurance Company - FORTUNE Magazine (2018)
            Top 5 Broker-Dealer - Financial Planning Magazine (2018)
            Expected dividend payout- 5.3 Billion (2018)
            $265 Billion in total company assets
            Job Type: Full-time
            Education: Bachelors
          </div>
        </div> */}
				</div>
			);
		}
	};

	render() {
		return (
			<div className={"right-sidebar " + (this.props.isCollapsed ? "collapsed" : "")}>
				<Button bsStyle="success" className="collapse-btn" onClick={this.props.onToggleCollapse}>
					<Ionicon icon={this.props.isCollapsed ? "ios-arrow-dropleft" : "ios-arrow-dropright"} />
				</Button>

				{this.displayResponsiveInformation()}
			</div>
		);
	}
}

QueryInformation.propTypes = {
	isCollapsed: PropTypes.bool.isRequired,
	previewResults: PropTypes.object.isRequired,
	onToggleCollapse: func.isRequired,
	onRunSearch: func.isRequired
};

export default QueryInformation;
