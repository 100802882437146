import React, { Component } from "react";
import { Row, Col, Button } from "antd";

class CookieNotice extends Component {
  state = {
    ackCookies: false,
    visible: true
  };

  componentDidMount() {
    const cookie = document.cookie;
    cookie.split(";").forEach(item => {
      if (item.includes("ai4jobs_ackCookie")) {
        if (item.includes("ai4jobs_ackCookie=true")) {
          this.setState({ visible: false, ackCookies: true });
        } else if (item.includes("ai4jobs_ackCookie=false")) {
          this.setState({ visible: true });
        }
      } else {
        document.cookie = "ai4jobs_ackCookie=false";
      }
    });
  }

  onChange = label => {
    if (label === "ACCEPT") {
      this.setState({ ackCookies: true, visible: false });
      document.cookie = "ai4jobs_ackCookie=true";
    } else {
      this.setState({ ackCookies: false, visible: false });
      document.cookie = "ai4jobs_ackCookie=false";
    }
  };

  render() {
    return (
      <div>
        {this.state.visible === true ? (
          <Row
            style={{
              position: "relative",
              background: "#2d3e50",
              color: "white",
              padding: "3rem",
              zIndex: 10,
              boxShadow: "0px 6px 3px rgba(0, 0, 0, 0.15)"
            }}
          >
            <Col
              span={24}
              style={{ paddingLeft: "4rem", paddingRight: "4rem" }}
            >
              <p>
                This website uses cookies to improve your experience. Continue
                to use the site as normal if you agree to the use of cookies. If
                you’d like to find out more about the cookies we use or to
                opt-out, please see ThisWayGlobal’s Cookie Policy.
              </p>
              <p>
                If you decline, your information won’t be tracked when you visit
                this website. A single cookie will be used in your browser to
                remember your preference not to be tracked.
              </p>
              <br />
              <Button
                style={{ marginRight: "2rem" }}
                onClick={() => this.onChange("ACCEPT")}
              >
                Accept
              </Button>
              <Button
                style={{ background: "transparent", color: "white" }}
                onClick={() => this.onChange("DECLINE")}
              >
                Decline
              </Button>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  }
}

export default CookieNotice;
