import React from 'react';
import {PropTypes} from 'prop-types';
import {Alert} from 'react-bootstrap';

export class AlertDismissable extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: this.props.show,
      alertStyle: this.props.alertStyle,
      message: this.props.message
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show) {
      this.setState({ show: true })
    }

    if (nextProps.alertStyle) {
      this.setState({ alertStyle: nextProps.alertStyle })
    }

    if (nextProps.message) {
      this.setState({ message: nextProps.message })
    }
  }

  handleDismiss() {
    this.setState({ show: false });
    this.props.onDismiss();
  }

  render() {
    return (
      this.state.show ?
      <Alert bsStyle={this.state.alertStyle} onDismiss={this.handleDismiss.bind(this)}>
        <p>{this.state.message}</p>
      </Alert>
      :
      null
    )
  }
}

AlertDismissable.propTypes = {
  show: PropTypes.bool.isRequired,
  alertStyle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func
};

export default AlertDismissable;