import initialState from './initialState';
import { USAGE_CREDIT_EXCEEDED, CLEAR_ALERT } from '../constants/actionTypes';

export default function alertsReducer(state = initialState.alerts, action) {
  switch (action.type) {
    
    case CLEAR_ALERT:
      return state.filter((item, index) => index !== action.index)

    case USAGE_CREDIT_EXCEEDED:
      return [ ...state, action.alert]

    default:
      return state;
  }
}
