import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../actions/testActions";
import {
  Button,
  Row,
  Col,
  Input,
  message,
  InputNumber,
  Divider,
  Icon,
  Checkbox,
  Spin
} from "antd";
import n from "numeral";
import sampleLogo from "../../../images/SampleLogo.png";
import DemoResults from "./demoResults";

const radioStyle = {
  height: "30px",
  lineHeight: "30px"
};
const IND = (
  <Icon
    className="animated__icon"
    type="loading"
    style={{ fontSize: 24, color: "#8492a2" }}
    spin
  />
);

const sidebarRow = { margin: "2rem 0rem" };

export class DemoTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      update: false,
      queryActive: false,
      workbookName: null,
      searchName: null,
      role: null,
      value: 1,
      strict: 1,
      exact: "OFF",
      pool: 0,
      summary: "OFF",
      title: "HINT",
      results: [],
      finalResults: [],
      stats: {},
      query: {},
      location: "",
      radius: "",
      depth: "",
      length: "",
      loading: false,
      tips: "Creating Search",
      formLength: 24,
      search: {
        searchName: null,
        searchTitle: null,
        searchDescription: null
      },
      finished: false
    };
  }

  componentDidMount() {}
  CreateQuery() {
    this.onLoad();

    let options = {
      STRICTNESS: this.state.strict,
      EXACT: this.state.exact,
      SUMMARY: this.state.summary,
      TITLE: this.state.title,
      POOL_SIZE: this.state.pool
    };

    const { workbookName, searchName, role, text } = this.state;
    const data = new FormData();
    data.append("text", text);
    data.append("location", this.state.location);
    data.append("radius", this.state.radius);
    data.append("careerDepth", this.state.depth);
    data.append("careerLength", this.state.length);
    data.append("searchName", searchName);
    data.append("workbookName", workbookName);
    data.append("role", role);

    this.props.actions.extractQuery(data, options).then(response => {
      this.setState(
        {
          results: response.results,
          stats: response.stats,
          query: response.query
        },
        () => {
          const arr = [];
          this.state.results.forEach(item => {
            arr.push(item.exportedIndividual);
          });
          this.setState({
            finished: true,
            finishedResults: arr,
            stats: response.stats
          });
        }
      );
    });
  }

  onNewSearch() {
    this.setState({ finished: false });
  }

  downloadFile(file, name) {
    let options = {
      direectory: "~/downloads",
      filename: name
    };
    saveFile(file, options, function(err) {
      if (err) throw err;
    });
  }

  onChange = e => {
    this.setState({ value: e.target.value });
  };

  getFieldTitle = label => {
    if (label === "totalHits") {
      return "Total Hits";
    } else if (label === "maxRelevancy") {
      return "Max";
    } else if (label === "minRelevancy") {
      return "Min";
    } else if (label === "stdDevRelevancy") {
      return "Relevance";
    } else if (label === "varianceRelevancy") {
      return "Variance";
    } else if (label === "aboveMeanCount") {
      return "Mean Count";
    } else if (label === "oneStdDevBelowMaxCount") {
      return "Std 1";
    } else if (label === "twoStdDevBelowMaxCount") {
      return "Std 2";
    } else if (label === "threeStdDevBelowMaxCount") {
      return "Std 3";
    } else if (label === "fiveStdDevBelowMaxCount") {
      return "Std 4";
    } else if (label === "meanRelevancy") {
      return "Mean";
    }
  };

  getRandomNumber() {
    return Math.random() * (1000 - 1) + 1;
  }

  updateNames = value => {
    this.setState({ workbookName: `${value + "-" + this.getRandomNumber()}` });
    this.setState({ searchName: `${value + "-" + this.getRandomNumber()}` });
  };

  onLoad() {
    this.setState({ loading: true }, () => {
      for (let i = 0; i < 10; i++) {
        setTimeout(() => {
          if (this.state.loading === true) {
            const phrases = [
              "Parsing Job Description",
              `Learning About ${this.state.role}s`,
              `Searching For ${this.state.role}s Candidates`,
              "Scoring Candidates",
              "Ranking Candidates"
            ];
            if (i < 5) {
              this.setState({ tip: phrases[i] });
            }
            if (this.state.finished === true) {
              this.setState({ tip: "Matching Completed" });
              setTimeout(() => {
                this.setState({ loading: false });
              }, 1500);
            }
          }
        }, i * 5000);
      }
    });
  }

  render() {
    return (
      <Col span={24} style={{ minHeight: "90vh" }}>
        {this.state.loading === false ? (
          <Col span={24} style={{ minHeight: "100%" }}>
            <Col span={24}>
              <Row className="accent__bar" style={{ margin: "2rem 4em" }}>
                <Row style={{ background: "white", padding: "2rem" }}>
                  {" "}
                  <img src={sampleLogo} style={{ width: "20%" }} />
                </Row>
                <Row
                  style={{
                    background: "#2dafba",
                    padding: "1rem 2.5rem",
                    fontSize: "2.5rem",
                    color: "white"
                  }}
                >
                  {this.state.finished === true ? (
                    <span style={{ fontWeight: "bold" }}>
                      Results For {this.state.role}
                    </span>
                  ) : (
                    <span style={{ fontWeight: "bold" }}>
                      Create A New Search
                    </span>
                  )}
                </Row>
              </Row>
            </Col>
            {this.state.finished === false ? (
              <Col span={24}>
                <Col span={16}>
                  <Row
                    className="tool__form"
                    style={{
                      background: "white",
                      margin: "0rem 2em 4em 4em",
                      padding: "6rem",
                      borderRadius: "0px 0px 5px 5px",
                      minHeight: "515px"
                    }}
                  >
                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          <Row>
                            <label>Name Your Search</label>
                          </Row>
                          <Row>
                            <Input
                              onChange={e => this.updateNames(e.target.value)}
                              style={{
                                width: "80%",
                                border: "0px solid transparent",
                                boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                              }}
                            />
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                            <label>Job Title</label>
                          </Row>
                          <Row>
                            <Input
                              onChange={e =>
                                this.setState({ role: e.target.value })
                              }
                              style={{
                                width: "80%",
                                border: "0px solid transparent",
                                boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 30 }}>
                        <Col span={24}>
                          <Row>
                            <label>Job Description</label>
                          </Row>
                          <Row>
                            <Input.TextArea
                              onChange={e =>
                                this.setState({ text: e.target.value })
                              }
                              rows={10}
                              style={{
                                border: "0px solid transparent",
                                boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                      {this.state.text &&
                      this.state.role &&
                      this.state.workbookName &&
                      this.state.searchName ? (
                        <Row style={{ marginTop: 30 }}>
                          <Button
                            onClick={e => this.CreateQuery()}
                            style={{
                              float: "right",
                              background: "#2dafba",
                              color: "white"
                            }}
                          >
                            <Icon
                              type="rocket"
                              style={{ marginRight: ".25rem" }}
                            />
                            Start Your Search
                          </Button>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={7}
                  style={{
                    background: "white",
                    padding: "0rem",
                    height: "515px",
                    marginRight: "2rem",
                    borderRadius: "0px 0px 5px 5px"
                  }}
                >
                  <Row>
                    <h4
                      style={{
                        marginTop: "2rem",
                        marginLeft: "2rem",
                        marginBottom: "1rem",
                        fontWeight: "bold"
                      }}
                    >
                      Advanced Search Options
                    </h4>
                  </Row>
                  <Divider style={{ margin: 0 }} />
                  <Row style={{ padding: "3rem 4rem", paddingBottom: "1rem" }}>
                    <h5 style={{ fontWeight: "bold" }}>Work History</h5>
                    <Divider style={{ margin: 0 }} />
                    <Row style={sidebarRow}>
                      <Checkbox style={{ marginRight: "1rem" }} /> Find Exact
                      Job Title Match
                    </Row>
                    <Row style={sidebarRow}>
                      <Checkbox style={{ marginRight: "1rem" }} /> Search All
                      Previous Work History
                    </Row>
                    <Divider style={{ margin: 0 }} />
                    <Row style={{ marginTop: "1rem" }}>
                      <InputNumber
                        style={{
                          marginRight: "1rem",
                          border: "0px solid transparent",
                          boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                        }}
                        min={0}
                        max={50}
                        onChange={e => {
                          this.setState({ length: e });
                        }}
                      />{" "}
                      Min. Years Of Experience
                    </Row>
                    <Row style={{ marginBottom: "2rem" }}>
                      <Row style={{ marginTop: "2rem" }}>
                        <InputNumber
                          style={{
                            marginRight: "1rem",
                            border: "0px solid transparent",
                            boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                          }}
                          min={0}
                          max={50}
                          onChange={e => {
                            this.setState({ depth: e });
                          }}
                        />{" "}
                        Number Of Jobs
                      </Row>
                    </Row>
                  </Row>
                  <Row style={{ padding: "0rem 4rem" }}>
                    <h5 style={{ fontWeight: "bold" }}>Location</h5>
                    <Divider
                      style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
                    />
                    <Row style={{ marginBottom: "1rem" }}>
                      <Row style={{ padding: 5 }}>
                        <Input
                          onChange={e =>
                            this.setState({ location: e.target.value })
                          }
                          placeholder={"Austin, TX"}
                          style={{
                            marginRight: "1rem",
                            border: "0px solid transparent",
                            boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                          }}
                        />
                      </Row>
                    </Row>
                    <Row style={sidebarRow}>
                      <Row>
                        <h5 style={{ fontWeight: "bold" }}>
                          Radius From Location
                        </h5>
                      </Row>
                      <Row style={{ padding: 5 }}>
                        <InputNumber
                          onChange={e => this.setState({ radius: e })}
                          placeholder={25}
                          style={{
                            marginRight: "1rem",
                            border: "0px solid transparent",
                            boxShadow: "1px 0px 5px rgba(0,0,0,0.15)"
                          }}
                        />{" "}
                        Miles
                      </Row>
                    </Row>
                  </Row>
                </Col>
              </Col>
            ) : (
              <DemoResults
                data={this.state.results}
                stats={this.state.stats}
                onNew={this.onNewSearch.bind(this)}
                name={this.state.searchName}
                new={this.onNewSearch.bind(this)}
              />
            )}
          </Col>
        ) : (
          <Col
            span={24}
            style={{
              width: "100%",
              height: "80vh"
            }}
          >
            <Col
              span={24}
              style={{
                height: "100%",
                padding: 20,
                borderRadius: 5
              }}
            >
              <div className="example">
                <Spin spinning={this.state.loading} indicator={IND} />
                {this.state.loading === true ? (
                  <h2 className="heading__animation">{this.state.tip}</h2>
                ) : (
                  <div>
                    <h1>{this.state.tip}</h1>
                  </div>
                )}
              </div>
            </Col>
          </Col>
        )}
      </Col>
    );
  }
}

DemoTool.propTypes = {};

function mapStateToProps(state) {
  return {
    test: state.DemoTool
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemoTool);
