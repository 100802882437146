import React from "react";
import { Row, Col, Divider } from "antd";
import phoneFormatter from "phone-formatter";
import PropTypes from "prop-types";

const attributeStyle = {
	fontWeight: "bold"
};

const unknownField = (label) => {
	return (
		<span>
			<span style={attributeStyle}>{label}:</span>
		</span>
	);
};

const CheckPhone = (phone, label) => {
	if (phone.includes("--redacted--")) {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span>
				<span className="redacted" style={{ marginLeft: "1rem" }}>
					{phone}
				</span>
			</span>
		);
	} else {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span> {phone || `No ${label} found`}
			</span>
		);
	}
};

const CheckEmails = (emails, label) => {
	if (emails.includes("--redacted--")) {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span>
				<span className="redacted" style={{ marginLeft: "1rem" }}>
					{emails}
				</span>
			</span>
		);
	} else {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span> {emails.split(",").join(", ") || `No ${label} found`}
			</span>
		);
	}
};

const CheckRedacted = (label, field) => {
	if (field.includes("redacted")) {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span>
				<span className="redacted">{field}</span>
			</span>
		);
	} else {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span> {field}
			</span>
		);
	}
};

const ResultsTableCard = ({ data }) => {
	const {
		currentCompany,
		currentTitle,
		currentIndustry,
		previousCompany,
		previousTitle,
		previousIndustry,
		school,
		location,
		languages,
		expertise,
		emails,
		otherEmails,
		personalEmails,
		phoneNumbers,
		degree
	} = data;

	return (
		<Row className="row__card">
			<Row>
				<Col span={8}>
					{currentCompany ? CheckRedacted("Company", currentCompany) : unknownField("Company")}
				</Col>
				<Col span={8}>{currentTitle ? CheckRedacted("Title", currentTitle) : unknownField("Title")}</Col>
				<Col span={8}>
					{currentIndustry ? CheckRedacted("Industry", currentIndustry) : unknownField("Industry")}
				</Col>
			</Row>
			<Divider />

			<Row type="flex" justify="start">
				<Col span={8}>
					{previousCompany ? CheckRedacted("Prev. Company", previousCompany) : unknownField("Prev. Company")}
				</Col>
				<Col span={8}>
					{previousTitle ? CheckRedacted("Prev. Title", previousTitle) : unknownField("Prev. Title")}
				</Col>
				<Col span={8}>
					{previousIndustry ? (
						CheckRedacted("Prev. Industry", previousIndustry)
					) : (
						unknownField("Prev. Industry")
					)}
				</Col>
			</Row>
			<Divider />

			<Row>
				<Col span={8}>{school ? CheckRedacted("School", school) : unknownField("School")}</Col>
				<Col span={8}>{degree ? CheckRedacted("Degree", degree) : unknownField("Degree")}</Col>
				<Col span={8}>{location ? CheckRedacted("Location", location) : unknownField("Location")}</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={8}>{languages ? CheckRedacted("Langauges", languages) : unknownField("Languages")}</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{expertise ? (
						<span>
							<span style={attributeStyle}>Expertise:</span> {expertise.split(",").join(", ")}
						</span>
					) : (
						unknownField("Expertise")
					)}
				</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{personalEmails.length > 1 ? (
						CheckEmails(personalEmails, "Personal Emails")
					) : (
						unknownField("Personal Emails")
					)}
				</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{otherEmails.length > 1 ? CheckEmails(otherEmails, "Other Emails") : unknownField("Other Emails")}
				</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{phoneNumbers.length > 1 ? (
						CheckPhone(phoneNumbers, "Phone Numbers")
					) : (
						unknownField("Phone Numbers")
					)}
				</Col>
			</Row>
		</Row>
	);
};

ResultsTableCard.propTypes = {
	data: PropTypes.object.isRequired
};

export default ResultsTableCard;
