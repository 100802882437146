import React from "react";
import { PropTypes, func } from "prop-types";
import { Modal, Button, Row, Col, Form, Input, notification, Icon } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;
export class WorkbookFormModal extends React.Component {
	constructor(props) {
		super(props);

		this.updatedWorkbook = null;
	}

	state = {
		name: null,
		dupe: false
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.workbook) {
			this.updatedWorkbook = { ...nextProps.workbook };
		}
	}

	openNotification = () => {
		notification.open({
			message: "Duplication Error",
			description: `The workbook name "${this.state
				.name}" already exist. Please make changes to the name and try again.`,
			icon: <Icon type="frown" style={{ color: "red" }} />
		});
	};

	handleChange = (event) => {
		if (event.target.value.length > 0) {
			this.setState({ name: event.target.value });
			this.updatedWorkbook[event.target.name] = event.target.value;
		} else {
			this.setState({ name: null });
		}
	};

	checkEntry(data) {
		const check = this.props.data.find((data) => data.name.toLowerCase() === this.state.name.toLowerCase());

		if (check === undefined) {
			this.props.onSave(this.updatedWorkbook);
		} else {
			this.setState({ dupe: true });
			this.openNotification();
		}
	}

	onKeyEvent(e) {
		if (e.key === "Enter") {
			this.props.onSave(this.updatedWorkbook);
		}
	}

	render() {
		const formItemLayout = {
			labelCol: {
				xs: { span: 10 },
				sm: { span: 6 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 16 }
			}
		};
		const tailFormItemLayout = {
			wrapperCol: {
				xs: {
					span: 24,
					offset: 0
				},
				sm: {
					span: 16,
					offset: 8
				}
			}
		};

		return (
			<Row>
				{this.props.workbook ? (
					<Modal
						title={this.props.workbook.id ? "Edit Workbook" : "New Workbook"}
						visible={this.props.workbook !== null}
						onOk={() => this.props.onSave(this.updatedWorkbook)}
						onCancel={() => this.props.onCancel()}
						footer={[
							<Button key="back" onClick={() => this.props.onCancel()}>
								Cancel
							</Button>,
							<Button
								key="submit"
								type="primary"
								disabled={this.state["name"] === null ? true : false}
								id={this.state["name"] === null ? "disabled__btn" : "primary__btn"}
								onClick={() => this.checkEntry(this.updateWorkbook)}
							>
								Save
							</Button>
						]}
					>
						<Form>
							<FormItem {...formItemLayout} label="Name">
								<Input
									placeholder="Enter Your Workbook Name"
									defaultValue={this.props.workbook.id ? this.props.workbook.name : null}
									name="name"
									style={{ background: "#fafafa" }}
									onChange={this.handleChange.bind(this)}
									onKeyDown={(e) => this.onKeyEvent(e)}
								/>
							</FormItem>
							<FormItem {...formItemLayout} label="Description">
								<TextArea
									rows={2}
									defaultValue={this.props.workbook.id ? this.props.workbook.description : null}
									style={{ background: "#fafafa" }}
									name="description"
									placeholder="Enter Your Workbook Description"
									onChange={this.handleChange.bind(this)}
									onKeyDown={(e) => this.onKeyEvent(e)}
								/>
							</FormItem>
						</Form>
					</Modal>
				) : null}
			</Row>
		);
	}
}

WorkbookFormModal.propTypes = {
	workbook: PropTypes.object,
	onSave: func.isRequired,
	onCancel: func.isRequired
};

const WorkForm = Form.create()(WorkbookFormModal);

export default WorkForm;
