import React, { Component } from "react";
import { Row, Col, Drawer, Divider, Icon, Skeleton, Button, Checkbox } from "antd";

class ResultsDrawer extends Component {
	componentWillMount() {}

	CreateFullName(value) {
		if (value.firstName) {
			if (value.firstName.includes("redacted") === true) {
				return (
					<span
						style={{ fontSize: "1.5rem" }}
						className="redacted__item"
					>{`${value.firstName} ${value.lastName}`}</span>
				);
			} else {
				const fullName = `${value.firstName} ${value.lastName}`;
				return fullName;
			}
		}
	}

	checkRedacted(value) {
		if (value) {
			if (value.includes("redacted")) {
				return <span className="redacted__item">{value}</span>;
			} else {
				return value;
			}
		}
	}

	checkSplitRedacted(value) {
		if (value) {
			if (value.includes("redacted")) {
				return <span className="redacted__item">{value}</span>;
			} else {
				if (value.includes(",")) {
					return value.split(",").join(", ");
				} else {
					return value;
				}
			}
		}
	}

	checkNull(data) {
		if (data !== null && data !== undefined) {
			return data;
		} else {
			return "";
		}
	}

	render() {
		const profile = this.props.profile.exportIndividual;

		const { onRedacted } = this.props;

		let workHistory = [
			{
				company: this.checkNull(profile.currentCompany),
				title: this.checkNull(profile.currentTitle),
				industry: this.checkNull(profile.currentIndustry)
			},
			{
				company: this.checkNull(profile.previousCompany),
				title: this.checkNull(profile.previousTitle),
				industry: this.checkNull(profile.previousIndustry)
			}
		];

		return (
			<Drawer
				placement="right"
				closable={false}
				onClose={() => this.props.onClose()}
				visible={this.props.visible}
				width={this.props.width}
			>
				<Row
					align="center"
					style={{
						background: "white",
						padding: 0,
						margin: 0,
						padding: 10,
						boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)"
					}}
				>
					<Row>
						<Col
							span={12}
							style={{
								float: "left",
								textAlign: "left",
								fontSize: "1.25rem",
								color: "#32b9ff",
								fontWeight: "bold",
								cursor: "pointer"
							}}
							onClick={() => this.props.onSwitchProfile("prev")}
						>
							<Icon type="left" /> Previous Candidate
						</Col>

						<Col
							span={12}
							style={{
								float: "right",
								textAlign: "right",
								fontSize: "1.25rem",
								color: "#32b9ff",
								fontWeight: "bold",
								cursor: "pointer"
							}}
							onClick={() => this.props.onSwitchProfile("next")}
						>
							Next Candidate <Icon type="right" />
						</Col>
					</Row>
					<Row span={24} style={{ marginTop: "1rem" }}>
						<Col style={{ textAlign: "center" }}>
							<h3>
								<span
									style={{
										background: onRedacted === true ? "#f7f7f7" : "transparent",
										color: onRedacted === true ? "#f7f7f7" : "#404040"
									}}
								>
									{this.props.name}
								</span>
							</h3>
						</Col>
					</Row>
					<Row span={24} style={{ marginTop: "1rem" }} type="flex" align="middle">
						<Col span={24} style={{ textAlign: "center" }}>
							<span style={{ fontWeight: "bold" }}>{this.checkRedacted(profile.currentTitle)}</span>
						</Col>
					</Row>
					<Divider style={{ margin: "1rem 0rem 1rem 0rem" }} />
					<Row>
						<Col span={12} style={{ textAlign: "center" }}>
							<label>Match:</label> {this.props.onMatch(this.props.profileIndex)}
						</Col>
						<Col span={12} style={{ textAlign: "center" }}>
							{this.props.selectedRowKeys.indexOf(this.props.profile.id) > -1 ? (
								<Checkbox
									checked={true}
									size="small"
									color="ghost"
									onClick={() => this.props.onSelect(this.props.profile.id)}
								>
									<span>Unselect Candidate</span>
								</Checkbox>
							) : (
								<Checkbox
									checked={false}
									size="small"
									onClick={() => this.props.onSelect(this.props.profile.id)}
								>
									<span>Select Candidate</span>
								</Checkbox>
							)}
						</Col>
					</Row>
				</Row>
				<Row
					align="center"
					style={{
						background: "white",
						marginTop: "4rem!important",
						padding: 25,
						margin: 10,
						boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)"
					}}
				>
					<Row>
						<h4 style={{ fontWeight: "bold" }}>
							<Col span={16}>
								<Icon type="user" /> Personal Info
							</Col>
							<Col span={8}>
								{profile.twitter !== null ? profile.twitter.includes("redacted") === false ? (
									<a
										href={profile.twitter}
										target="_blank"
										style={{ marginLeft: "1rem", float: "right" }}
									>
										<Icon theme={"outlined"} type="twitter" className="icon__twitter" />
									</a>
								) : null : null}
								{profile.gitHub !== null ? profile.gitHub.includes("redacted") === false ? (
									<a
										href={profile.gitHub}
										target="_blank"
										style={{ marginLeft: "1rem", float: "right" }}
									>
										<Icon theme={"filled"} type="github" className="icon__github" />
									</a>
								) : null : null}
								{profile.linkedIn !== null ? profile.linkedIn.includes("redacted") === false ? (
									<a
										href={profile.linkedIn}
										target="_blank"
										style={{ marginLeft: "1rem", float: "right" }}
									>
										<Icon theme={"filled"} type="linkedin" className="icon__linkedin" />
									</a>
								) : null : null}
								{profile.facebook !== null ? profile.facebook.includes("redacted") === false ? (
									<a
										href={profile.facebook}
										target="_blank"
										style={{ marginLeft: "1rem", float: "right" }}
									>
										<Icon theme={"filled"} type="facebook" className="icon__facebook" />
									</a>
								) : null : null}
								{profile.instagram !== null ? profile.instagram.includes("redacted") === false ? (
									<a
										href={profile.instagram}
										target="_blank"
										style={{ marginLeft: ".25rem", float: "right" }}
									>
										<Icon theme={"filled"} type="instagram" className="icon__instagram" />
									</a>
								) : null : null}
							</Col>
						</h4>
					</Row>
					<Divider style={{ margin: "0rem 0rem 1rem 0rem" }} />
					<Row>
						<Row>
							{profile.firstName ? (
								<Col span={12}>
									<label>Name:</label>{" "}
									<span
										style={{
											background: onRedacted === true ? "#f7f7f7" : "transparent",
											color: onRedacted === true ? "#f7f7f7" : "#404040"
										}}
									>
										{this.props.name}
									</span>
								</Col>
							) : null}
							{profile.location ? (
								<Col span={12}>
									<label>Location:</label> {profile.location}
								</Col>
							) : null}

							{profile.school ? (
								<Col span={24}>
									<Divider style={{ margin: "1rem 0rem 1rem 0rem" }} />
									<label>School:</label> {profile.school}
								</Col>
							) : null}
							{profile.degree ? (
								<Col span={24}>
									<Divider style={{ margin: "1rem 0rem 1rem 0rem" }} />
									<label>Degree:</label> {profile.degree}
								</Col>
							) : null}
							{profile.personalEmails ? (
								<Col span={24}>
									<Divider style={{ margin: "1rem 0rem 1rem 0rem" }} />
									<label>Personal Emails:</label>{" "}
									<span
										style={{
											background: onRedacted === true ? "#f7f7f7" : "transparent",
											color: onRedacted === true ? "#f7f7f7" : "#404040"
										}}
									>
										{this.props.email}
									</span>
								</Col>
							) : null}

							{profile.phoneNumbers ? (
								<Col span={24}>
									<Divider style={{ margin: "1rem 0rem 1rem 0rem" }} />
									<label>Phone Numbers:</label>{" "}
									<span
										style={{
											background: onRedacted === true ? "#f7f7f7" : "transparent",
											color: onRedacted === true ? "#f7f7f7" : "#404040"
										}}
									>
										{" "}
										{this.props.phone}
									</span>
								</Col>
							) : null}
						</Row>
					</Row>
				</Row>
				{profile.expertise ? (
					<Row
						align="center"
						style={{
							background: "white",
							marginTop: "4rem!important",
							padding: 25,
							margin: 10,
							boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)"
						}}
					>
						<Row>
							<h4 style={{ fontWeight: "bold" }}>
								<Icon type="gold" /> Expertise
							</h4>
						</Row>
						<Divider style={{ margin: "0rem 0rem 1rem 0rem" }} />
						<Row>
							<Row>
								{profile.firstName ? (
									<Col span={24}>
										<label style={{ fontWeight: "bold" }}>Skills:</label>{" "}
										{profile.expertise.split(",").join(", ")}
									</Col>
								) : null}
							</Row>
						</Row>
					</Row>
				) : null}
				{workHistory[0].company !== null ? (
					<Row
						align="center"
						style={{
							background: "white",
							marginTop: "4rem!important",
							padding: 25,
							margin: 10,
							boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)"
						}}
					>
						<Row>
							<h4 style={{ fontWeight: "bold" }}>
								<Icon type="alert" /> Work History
							</h4>
						</Row>
						<Divider style={{ margin: ".1rem 0rem 1rem 0rem" }} />
						<Row>
							{workHistory.map((items, index) => {
								return (
									<Col span={24} key={index}>
										{items.company ? (
											<Col span={24} style={{ marginBottom: "1rem" }}>
												<span>
													<label style={{ textTransform: "capitalize" }}>
														{index === 1 ? "Previous Company" : "Current Company"}:
													</label>{" "}
													{items.company}
												</span>
											</Col>
										) : null}
										{items.industry ? (
											<Col span={24} style={{ marginBottom: "1rem" }}>
												<span>
													<label style={{ textTransform: "capitalize" }}>
														{index === 1 ? "Previous Industry" : "Current Industry"}:
													</label>{" "}
													{items.industry}
												</span>
											</Col>
										) : null}
										{items.title ? (
											<Col span={24} style={{ marginBottom: "1rem" }}>
												<span>
													<label style={{ textTransform: "capitalize" }}>
														{index === 1 ? "Previous Title" : "Current Title"}:
													</label>{" "}
													{items.title}
												</span>
											</Col>
										) : null}
										{index === 0 && items.company ? (
											<Divider style={{ margin: "1rem 0rem 1rem 0rem" }} />
										) : null}
									</Col>
								);
							})}
						</Row>
					</Row>
				) : null}
			</Drawer>
		);
	}
}

export default ResultsDrawer;
