import axios from 'axios';
import * as types from '../constants/actionTypes';

export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        localStorage.removeItem('accessToken');
        store.dispatch({
          type: types.LOGOUT
        });
      } else if (error.response.status === 402) {
        store.dispatch({
          type: types.USAGE_CREDIT_EXCEEDED,
          alert: {
            style: "danger",
            message: "You have exhausted your usage credits. Please contact your Administrator or ThisWay."
          }
        });
      }

      return Promise.reject(error);
    });
  }
};
