import {
  GET_QUERY,
  ADD_FILTER,
  REMOVE_FILTER,
  UPDATE_SUB_FILTER
} from "../constants/actionTypes";
import initialState from "./initialState";
import update from "immutability-helper";

export default function queryReducer(state = initialState.query, action) {
  switch (action.type) {
    case GET_QUERY:
      if (!action.payload.annotations) {
        action.payload.annotations = { cards: [] };
      } else if (!action.payload.annotations.cards) {
        action.payload.annotations.cards = [];
      }

      return action.payload;

    case ADD_FILTER:
      return update(state, {
        annotations: {
          cards: { $push: [action.filterKey] }
        }
      });

    case REMOVE_FILTER:
      return update(state, {
        annotations: {
          cards: arr => arr.filter(card => card != action.filterKey)
        }
      });

    case UPDATE_SUB_FILTER:
      return update(state, {
        query: {
          search: {
            [action.payload.filterIndex]: {
              subCategories: {
                [action.payload.subFilterIndex]: {
                  [action.payload.filterType]: {
                    $set: action.payload.newValues
                  }
                }
              }
            }
          }
        }
      });

    default:
      return state;
  }
}
