import React from "react";
import { Row, Col, Divider, Icon } from "antd";
import PropTypes from "prop-types";

const attributeStyle = {
	fontWeight: "bold",
	marginRight: "1rem"
};

const unknownField = (label) => {
	return (
		<span>
			<span style={attributeStyle}>{label}:</span>
		</span>
	);
};

const CheckPhone = (phone, label) => {
	if (phone.includes("--redacted--")) {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span>
				<span className="redacted" style={{ marginLeft: "1rem" }}>
					{phone}
				</span>
			</span>
		);
	} else {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span> {phone || `No ${label} found`}
			</span>
		);
	}
};

const CheckEmails = (emails, label) => {
	if (emails.includes("--redacted--")) {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span>
				<span className="redacted" style={{ marginLeft: "1rem" }}>
					{emails}
				</span>
			</span>
		);
	} else {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span> {emails.split(",").join(", ") || "No emails found"}
			</span>
		);
	}
};

const CheckRedacted = (label, field) => {
	if (field.includes("redacted")) {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span>
				<span className="redacted" style={{ marginRight: "1rem" }}>
					{field}
				</span>
			</span>
		);
	} else {
		return (
			<span>
				<span style={attributeStyle}>{label}:</span> {field}
			</span>
		);
	}
};

const createSocial = (link, label) => {
	if (link.includes("redacted") === true) {
		return (
			<Icon
				type={label}
				theme={label === "twitter" ? "outlined" : "filled"}
				className={`icon__${label}--disabled`}
			/>
		);
	} else if (link.includes("redacted") === false) {
		return (
			<a href={link} target="_blank">
				<Icon
					type={label}
					theme={label === "twitter" ? "outlined" : "filled"}
					className={`icon__${label}
          }`}
				/>
			</a>
		);
	}
};

const ResultsListCards = ({ data }) => {
	const {
		currentCompany,
		currentTitle,
		currentIndustry,
		previousCompany,
		previousTitle,
		previousIndustry,
		school,
		location,
		languages,
		expertise,
		emails,
		otherEmails,
		personalEmails,
		degree,
		twitter,
		linkedIn,
		facebook,
		gitHub,
		instagram,
		relevancy,
		phoneNumbers,
		score,
		careerLength,
		careerCount,
		firstName,
		lastName
	} = data;

	return (
		<Row
			style={{
				marginBottom: "4rem",
				background: "white",
				padding: "4rem",
				boxShadow: "0px 6px 13px rgba(0,0,0,0.25)",
				borderRadius: "1rem"
			}}
		>
			<Row>
				<Col span={6}>{CheckRedacted("Name", " " + firstName + " " + lastName)} </Col>
				<Col span={3}>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(5, 1fr)",
							justifyItems: "center",
							marginBottom: "4rem"
						}}
					>
						{twitter !== null ? <div>{createSocial(twitter, "twitter")}</div> : null}
						{linkedIn !== null ? <div>{createSocial(linkedIn, "linkedin")}</div> : null}
						{facebook !== null ? <div>{createSocial(facebook, "facebook")}</div> : null}
						{gitHub !== null ? <div>{createSocial(gitHub, "github")}</div> : null}
						{instagram !== null ? <div>{createSocial(instagram, "instagram")}</div> : null}
					</div>
				</Col>
			</Row>

			<Row>
				<Col span={5}>
					<span>
						<span style={attributeStyle}>Relevance:</span> {relevancy}
					</span>
				</Col>
				<Col span={5}>
					<span>
						<span style={attributeStyle}>Score:</span> {score}
					</span>
				</Col>
				<Col span={5}>
					<span>
						<span style={attributeStyle}>Career Length:</span> {careerLength}
					</span>
				</Col>
				<Col span={4}>
					<span>
						<span style={attributeStyle}>Job Count: </span> {careerCount}
					</span>
				</Col>
			</Row>
			<Divider />
			<Row>
				<Col span={8}>
					{currentCompany ? CheckRedacted("Company", currentCompany) : unknownField("Company")}
				</Col>
				<Col span={8}>{currentTitle ? CheckRedacted("Title", currentTitle) : unknownField("Title")}</Col>
				<Col span={8}>
					{currentIndustry ? CheckRedacted("Industry", currentIndustry) : unknownField("Industry")}
				</Col>
			</Row>
			<Divider />

			<Row type="flex" justify="start">
				<Col span={8}>
					{previousCompany ? CheckRedacted("Prev. Company", previousCompany) : unknownField("Prev. Company")}
				</Col>
				<Col span={8}>
					{previousTitle ? CheckRedacted("Prev. Title", previousTitle) : unknownField("Prev. Title")}
				</Col>
				<Col span={8}>
					{previousIndustry ? (
						CheckRedacted("Prev. Industry", previousIndustry)
					) : (
						unknownField("Prev. Industry")
					)}
				</Col>
			</Row>
			<Divider />

			<Row>
				<Col span={8}>{school ? CheckRedacted("School", school) : unknownField("School")}</Col>
				<Col span={8}>{degree ? CheckRedacted("Degree", degree) : unknownField("Degree")}</Col>
				<Col span={8}>{location ? CheckRedacted("Location", location) : unknownField("Location")}</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={8}>{languages ? CheckRedacted("Langauges", languages) : unknownField("Languages")}</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{expertise ? (
						<span>
							<span style={attributeStyle}>Expertise:</span> {expertise.split(",").join(", ")}
						</span>
					) : (
						unknownField("Expertise")
					)}
				</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{personalEmails.length > 1 ? (
						CheckEmails(emails, "Personal Emails")
					) : (
						unknownField("Personal Emails")
					)}
				</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{otherEmails.length > 1 ? CheckEmails(emails, "Other Emails") : unknownField("Other Emails")}
				</Col>
			</Row>
			<Divider />
			<Row type="flex" justify="start">
				<Col span={24}>
					{phoneNumbers.length > 1 ? (
						CheckPhone(phoneNumbers, "Phone Numbers")
					) : (
						unknownField("Phone Numbers")
					)}
				</Col>
			</Row>
		</Row>
	);
};

ResultsListCards.propTypes = {
	data: PropTypes.object.isRequired
};

export default ResultsListCards;
