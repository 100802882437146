export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const SET_TEAM = "SET_TEAM";
export const CLEAR_USER = "CLEAR_USER";

export const GET_GHKEY = "GET_GHKEY";

export const GET_WORKBOOKS = "GET_WORKBOOKS";

export const TEST_ACTION = "TEST_ACTION";

export const GET_QUERY = "GET_QUERY";

export const ADD_FILTER = "ADD_FILTER";

export const REMOVE_FILTER = "REMOVE_FILTER";

export const UPDATE_SUB_FILTER = "UPDATE_SUB_FILTER";

export const TOGGLE_QUERY_OPTIONS_PANEL = "TOGGLE_QUERY_OPTIONS_PANEL";
export const TOGGLE_QUERY_INFORMATION_PANEL = "TOGGLE_QUERY_INFORMATION_PANEL";

export const UPDATE_CURRENT_WORKBOOK = "UPDATE_CURRENT_WORKBOOK";
export const UPDATE_CURRENT_QUERY = "UPDATE_CURRENT_QUERY";
export const UPDATE_DUPLICATE_MODAL = "UPDATE_DUPLICATE_MODAL";
export const UPDATE_MOVE_MODAL = "UPDATE_MOVE_MODAL";
export const UPDATE_SHARE_MODAL = "UPDATE_SHARE_MODAL";
export const UPDATE_SHARE_QUERY_MODAL = "UPDATE_SHARE_QUERY_MODAL";

export const GET_SEARCH = "GET_SEARCH";
export const GET_PREVIEW = "GET_PREVIEW";

export const SET_SAVING = "SET_SAVING";

export const SEND_RESET_EMAIL = "SEND_RESET_EMAIL";
export const SEND_RESET_EMAIL_COMPLETE = "SEND_RESET_EMAIL_COMPLETE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_COMPLETE = "RESET_PASSWORD_COMPLETE";
export const SET_RESET_PASSWORD_ERROR = "SET_RESET_PASSWORD_ERROR";

export const CLEAR_ALERT = "CLEAR_ALERT";
export const USAGE_CREDIT_EXCEEDED = "USAGE_CREDIT_EXCEEDED";

export const ENRICH_SUCCESS = "ENRICH_SUCCESS";
export const ENRICH_FAILED = "ENRICH_FAILED";
export const ENRICH_PENDING = "ENRICH_PENDING";

export const GET_GHCLIENTS = "GET_GHCLIENTS";
export const UPDATE_GHCLIENT = "UPDATE_GHCLIENT";
export const GET_GHOPTIONS = "GET_GHOPTIONS";
export const UPDATE_GHOPTIONS = "UPDATE_GHOPTIONS";
export const CREATE_GHOPTIONS = "CREATE_GHOPTIONS";
