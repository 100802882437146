/* eslint import/no-named-as-default: 0 */

import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import WorkbooksContainer from "./containers/WorkbooksContainer";
import LoginContainer from "./containers/LoginContainer";
import QueryContainer from "./containers/QueryContainer";
import ResultsContainer from "./containers/ResultsContainer";
import GreenhouseKey from "./containers/GreenhouseKey";
import ForgotPasswordContainer from "./containers/ForgotPasswordContainer";
import ResetPasswordContainer from "./containers/ResetPasswordContainer";
import HeaderNavbar from "./HeaderNavbar";
import TermsContainer from "./containers/TermsContainer";
import Alerts from "./Alerts";
import PropTypes from "prop-types";
import React from "react";
import { hot } from "react-hot-loader";
import { Layout } from "antd";
import FooterBar from "./FooterBar";
import QueryTester from "./containers/QueryTester";
import logo from "../images/PoweredBy.svg";
import SFLogin from "./containers/SFLoginContainer";
import GreenhouseUser from "./containers/GreenhouseUser";
import DemoTool from "./containers/Tools/demoTool.js";
import Estimate from "./containers/GreenhouseEstimate.js";

const { Footer } = Layout;
// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {
	render() {
		return (
			<div id="react-root" style={{ minHeight: "90vh" }}>
				<div>
					<HeaderNavbar />

					<Alerts />
					<Switch>
						<Route exact path="/" component={LoginContainer} />
						<Route path="/login" component={LoginContainer} />
						<Route path="/terms" component={TermsContainer} />
						<Route path="/salesforce/login" component={SFLogin} />
						<Route path="/forgotpassword" component={ForgotPasswordContainer} />
						<Route path="/reset/:guid" component={ResetPasswordContainer} />
						<PrivateRoute exact path="/test" component={QueryTester} />
						<PrivateRoute exact path="/reveal/user" component={GreenhouseUser} />
						<PrivateRoute path="/reveal/estimate" component={Estimate} />
						<PrivateRoute path="/reveal/create" component={GreenhouseKey} />
						<PrivateRoute path="/tool" component={DemoTool} />
						<PrivateRoute path="/workbooks" component={WorkbooksContainer} />
						<PrivateRoute path="/workbook/:workbookId/query/:queryId" component={QueryContainer} />
						<PrivateRoute path="/workbook/:workbookId/template/:queryId" component={QueryContainer} />
						<PrivateRoute path="/search/:workbookId/query/:queryId" component={ResultsContainer} />
						<Route component={LoginContainer} />
					</Switch>
					<Footer
						style={{
							textAlign: "center",
							margin: 0,
							padding: 0,
							background: "#edf0f1",
							width: "100%",
							paddingTop: 10
						}}
					>
						<img src={logo} style={{ width: "150px", marginBottom: "1rem" }} />
					</Footer>
				</div>
			</div>
		);
	}
}

App.propTypes = {
	children: PropTypes.element
};

export default hot(module)(App);
