import * as types from '../constants/actionTypes';

export function toggleQueryOptionsPanel(isCollapsed) {
  return function (dispatch) {
    return dispatch({
      type: types.TOGGLE_QUERY_OPTIONS_PANEL,
      isCollapsed
    });
  };
}

export function toggleQueryInformationPanel(isCollapsed) {
  return function (dispatch) {
    return dispatch({
      type: types.TOGGLE_QUERY_INFORMATION_PANEL,
      isCollapsed
    });
  };
}