export default {
	session: {
		auth: !!localStorage.accessToken,
		attempt: false
	},
	user: {
		username: "",
		fullName: "",
		phone: "",
		email: "",
		team: [],
		usage: {}
	},
	workbooks: [ { name: null, queries: [] } ],
	queryLayout: {
		optionsCollapsed: false,
		informationCollapsed: false
	},
	query: {
		query: {
			search: []
		},
		annotations: {
			cards: []
		}
	},
	forms: {
		currentWorkbook: null,
		currentQuery: {
			workbook: null,
			query: null
		},
		duplicateModal: {
			workbook: null,
			query: null
		},
		moveModal: {
			workbook: null,
			team: [],
			status: false,
			data: [],
			share: false
		},
		shareModal: {
			status: false
		},
		shareQuery: {
			status: false,
			workbook: [],
			query: []
		}
	},
	previewResults: {},
	searchResults: {
		resultSetId: "",
		results: [],
		status: false,
		error: false
	},
	passwordLink: {
		isLoading: false,
		hasError: false,
		message: ""
	},
	passwordReset: {
		isLoading: false,
		hasError: false,
		message: "",
		resetSuccess: false
	},
	greenHouse: {
		name: "",
		clientKey: "",
		clientSecret1: "",
		clientSecret2: "",
		atsApiKey: "",
		enabled: false,
		clients: []
	},
	options: [],
	queryTester: {
		cleanedContent: "",
		keyPhrases: [],
		query: "",
		stats: {},
		results: []
	},
	alerts: []
};
