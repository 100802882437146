import initialState from "./initialState";
import { GET_WORKBOOKS } from "../constants/actionTypes";

export default function workbooksReducer(
  state = initialState.workbooks,
  action
) {
  switch (action.type) {
    case GET_WORKBOOKS:
      return action.payload;

    default:
      return state;
  }
}
