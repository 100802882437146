import React from "react";
import { PropTypes, func } from "prop-types";
import { Row, Col, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Popconfirm } from "antd";
import Ionicon from "react-ionicons";
import moment from "moment";

export class Workbook extends React.Component {
	render() {
		const openTooltip = (
			<Tooltip id="tooltip" className="tooltip">
				Open
			</Tooltip>
		);

		const editTooltip = (
			<Tooltip id="tooltip" className="tooltip">
				Edit
			</Tooltip>
		);

		const duplicateTooltip = (
			<Tooltip id="tooltip" className="tooltip">
				Duplicate
			</Tooltip>
		);

		const ShareTooltip = (
			<Tooltip id="tooltip" className="tooltip">
				Share Query
			</Tooltip>
		);

		const deleteTooltip = (
			<Tooltip id="tooltip" className="tooltip">
				Delete
			</Tooltip>
		);

		let queries = this.props.workbook.queries.map((query, index) => {
			return (
				<tr key={index}>
					<td>{query.name}</td>
					<td>{query.description}</td>
					<td>{moment(query.createdAt).format("MMM Do YYYY, h:mm:ss a")}</td>
					<td>
						<OverlayTrigger key={"open"} placement="top" overlay={openTooltip}>
							<Button
								id="primary__btn"
								type="primary"
								icon="form"
								size="default"
								onClick={() => this.props.onOpenQuery(this.props.workbook, query)}
							/>
						</OverlayTrigger>
						<OverlayTrigger key={"edit"} placement="top" overlay={editTooltip}>
							<Button
								id="primary__btn"
								type="primary"
								icon="edit"
								size="default"
								onClick={() => this.props.onEditQuery(this.props.workbook, query)}
							/>
						</OverlayTrigger>
						<OverlayTrigger key={"duplicate"} placement="top" overlay={duplicateTooltip}>
							<Button
								id="primary__btn"
								type="primary"
								icon="copy"
								size="default"
								onClick={() => this.props.onDuplicateQuery(this.props.workbook, query)}
							/>
						</OverlayTrigger>
						{0 > 1 ? (
							<OverlayTrigger key={"share"} placement="top" overlay={ShareTooltip}>
								<Button
									id="primary__btn"
									type="primary"
									icon="share-alt"
									size="default"
									onClick={() => this.props.onShareQuery(this.props.workbook, query)}
								/>
							</OverlayTrigger>
						) : null}
						<Popconfirm
							placement="topRight"
							title={"Are you sure you want to delete the query?"}
							onConfirm={() => this.props.onDeleteQuery(this.props.workbook, query)}
							okText="Yes"
							cancelText="No"
						>
							<OverlayTrigger key={"delete"} placement="top" overlay={deleteTooltip}>
								<Button id="primary__btn" type="primary" icon="delete" size="default" />
							</OverlayTrigger>
						</Popconfirm>
					</td>
				</tr>
			);
		});

		return (
			<div
				className="workbook"
				style={{
					padding: "2rem",
					borderRadius: 5,
					boxShadow: "rgba(0, 0, 0, 0.15) 2px 6px 10px"
				}}
			>
				<Row>
					<Col xs={12}>
						<h2 className="thin">
							{this.props.workbook.name}
							<span className="pull-right">
								<OverlayTrigger key={"edit"} placement="top" overlay={editTooltip}>
									<Button
										id="primary__btn"
										type="primary"
										icon="edit"
										size="default"
										onClick={() => this.props.onEditWorkbook(this.props.workbook)}
									/>
								</OverlayTrigger>
								<OverlayTrigger key={"duplicate"} placement="top" overlay={duplicateTooltip}>
									<Button
										id="primary__btn"
										type="primary"
										icon="copy"
										size="default"
										onClick={() => this.props.onDuplicateWorkbook(this.props.workbook)}
									/>
								</OverlayTrigger>

								<Popconfirm
									placement="topRight"
									title={"Are you sure you want to delete the workbook?"}
									onConfirm={() => this.props.onDeleteWorkbook(this.props.workbook)}
									okText="Yes"
									cancelText="No"
								>
									<OverlayTrigger key={"delete"} placement="top" overlay={deleteTooltip}>
										<Button id="primary__btn" type="primary" icon="delete" size="default" />
									</OverlayTrigger>
								</Popconfirm>
							</span>
						</h2>

						<p>{this.props.workbook.description}</p>
						<p>Created: {moment(this.props.workbook.createdAt).format("MMM Do YYYY, h:mm:ss a")}</p>
						<p className="black">
							<span>{this.props.workbook.queries.length} </span>
							{this.props.workbook.queries.length === 1 ? <span>Search</span> : <span>Searches</span>}
						</p>
					</Col>
				</Row>

				<Table condensed>
					<thead>
						<tr>
							<th>Name</th>
							<th>Description</th>
							<th>Created At</th>
							<th />
						</tr>
					</thead>
					<tbody>{queries}</tbody>
				</Table>

				<Button
					id="primary__btn"
					type="primary"
					icon="plus"
					size="default"
					onClick={() => this.props.onAddQuery(this.props.workbook, this.props.template)}
				>
					{" "}
					New Query
				</Button>
				<Button
					id="primary__btn"
					type="primary"
					size="default"
					onClick={() => this.props.onMoveQuery(this.props.workbook, this.props.data)}
				>
					Move Queries
				</Button>
			</div>
		);
	}
}

Workbook.propTypes = {
	workbook: PropTypes.object.isRequired,
	onEditWorkbook: func.isRequired,
	onDuplicateWorkbook: func.isRequired,
	onDeleteWorkbook: func.isRequired,
	onAddQuery: func.isRequired,
	onOpenQuery: func.isRequired,
	onEditQuery: func.isRequired,
	onDuplicateQuery: func.isRequired,
	onDeleteQuery: func.isRequired
};

export default Workbook;
