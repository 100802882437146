import {TOGGLE_QUERY_OPTIONS_PANEL, TOGGLE_QUERY_INFORMATION_PANEL} from '../constants/actionTypes';
import initialState from './initialState';

export default function queryLayoutReducer(state = initialState.queryLayout, action) {
  switch (action.type) {
    case TOGGLE_QUERY_OPTIONS_PANEL:
      return {
        ...state, optionsCollapsed: action.isCollapsed
      };

    case TOGGLE_QUERY_INFORMATION_PANEL:
      return {
        ...state, informationCollapsed: action.isCollapsed
      };

    default:
      return state;
  }
}
