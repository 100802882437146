import * as types from "../constants/actionTypes";

export function updateCurrentWorkbook(workbook) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_CURRENT_WORKBOOK,
      workbook
    });
    return Promise.resolve()
  };
}

export function updateCurrentQuery(workbook, query) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_CURRENT_QUERY,
      query: {
        workbook: workbook,
        query: query
      }
    });
  };
}

export function updateDuplicateModal(workbook, query) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_DUPLICATE_MODAL,
      duplicateModal: {
        workbook: workbook,
        query: query
      }
    });
  };
}

export function updateMoveModal(workbook, data, template) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_MOVE_MODAL,
      moveModal: {
        workbook: workbook,
        data: data,
        status: true,
        template: template
      }
    });
  };
}

export function updateShareModal(status) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_SHARE_MODAL,
      shareModal: {
        status: status
      }
    });
  };
}

export function updateShareQueryModal(status, workbook, query) {
  return function(dispatch) {
    return dispatch({
      type: types.UPDATE_SHARE_QUERY_MODAL,
      shareQuery: {
        workbook: workbook,
        status: status,
        query: query
      }
    });
  };
}
