export const PREVIEW_COLUMNS = [
  // {
  //   key: "resultSetId",
  //   display: "ID"
  // },
  // {
  //   key: "hits",
  //   display: "Hits"
  // },
  {
    key: "totalHits",
    display: "Total Hits"
  },
  {
    key: "maxRelevancy",
    display: "Max Relevancy"
  },
  {
    key: "minRelevancy",
    display: "Min Relevancy"
  },
  {
    key: "meanRelevancy",
    display: "Mean Relevancy"
  },
  {
    key: "stdDevRelevancy",
    display: "StDev Relevancy"
  },
  {
    key: "varianceRelevancy",
    display: "Var Relevancy"
  },
  {
    key: "aboveMeanCount",
    display: "# Above Mean"
  },
  {
    key: "oneStdDevBelowMaxCount",
    display: "1 X StDev"
  },
  {
    key: "twoStdDevBelowMaxCount",
    display: "2 X StDev"
  },
  {
    key: "threeStdDevBelowMaxCount",
    display: "3 X StDev"
  },
  {
    key: "fiveStdDevBelowMaxCount",
    display: "# 5 X StDev"
  }
]
 