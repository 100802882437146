import React, { Component } from "react";
import m from "moment";
import { Table, Button, Col, Row, Divider, Icon, Pagination } from "antd";

class QueryView extends Component {
	state = {
		data: this.props.currentView
	};
	componentWillReceiveProps(nextProps) {
		this.setState({ data: nextProps.currentView });
	}
	render() {
		const { workbooks, currentView } = this.props;
		const columns = [
			{
				title: "Search Name",
				dataIndex: "name",
				key: "name"
			},
			{
				title: "Description",
				dataIndex: "description",
				key: "description"
			},
			{
				title: "Created",
				render: (x) => {
					const format = m(x.createdAt).format("MM/DD/YY");
					return format;
				}
			},
			{
				title: "",
				render: (x) => (
					<span>
						<span
							style={{ color: "#108ee9", cursor: "pointer" }}
							onClick={() => this.props.onOpenQuery(currentView, x)}
						>
							View
						</span>
						<Divider type="vertical" />
						<span
							style={{ color: "#108ee9", cursor: "pointer" }}
							onClick={() => this.props.onEditQuery(currentView, x)}
						>
							Edit
						</span>
						<Divider type="vertical" />
						<span
							style={{ color: "#108ee9", cursor: "pointer" }}
							onClick={() => this.props.onShareQuery(currentView, x)}
						>
							Share
						</span>
						<Divider type="vertical" />
						<span
							style={{ color: "#108ee9", cursor: "pointer" }}
							onClick={() => this.props.onDuplicateQuery(currentView, x)}
						>
							Duplicate
						</span>
						<Divider type="vertical" />
						<span
							style={{ color: "#108ee9", cursor: "pointer" }}
							onClick={() => this.props.onDeleteQuery(currentView, x)}
						>
							Delete
						</span>
					</span>
				)
			}
		];
		return (
			<div>
				<Col
					style={{
						padding: 15,
						display: this.state.data ? "inherit" : "flex",
						alignItems: "center",
						justifyContent: "center",
						alignSelf: "center",
						minHeight: "632px",
						background: "white",
						borderRight: "1px solid #e7e7e7",
						borderBottom: "1px solid #e7e7e7",
						borderRadius: 4,
						boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)"
					}}
				>
					{this.state.data ? (
						<div>
							{this.state.data ? (
								<Row style={{ marginTop: "2rem" }}>
									<Col span={12}>
										<h3>{currentView ? currentView.name : null}</h3>
									</Col>
									<Col span={12}>
										<Icon
											type="delete"
											className="icon__delete"
											theme="filled"
											style={{ float: "right", marginLeft: "2rem", paddingTop: ".35rem" }}
											onClick={() => this.props.onDeleteWorkbook(currentView)}
										/>
										<Button
											size="small"
											style={{ float: "right" }}
											onClick={() => this.props.onEditWorkbook(currentView)}
										>
											<Icon type="edit" /> Edit Workbook
										</Button>
										<Button
											size="small"
											style={{ float: "right", marginRight: "1rem" }}
											onClick={() => this.props.onShareWorkbook(currentView)}
										>
											<Icon type="share-alt" /> Share Workbook
										</Button>
									</Col>
								</Row>
							) : null}
							<Row style={{ marginTop: "4rem" }}>
								{this.state.data ? (
									<Table
										rowKey={(record) => record.id}
										dataSource={this.state.data.queries}
										columns={columns}
										pagination={{ pageSize: 6 }}
									/>
								) : null}
							</Row>
							{this.state.data ? (
								<Row style={{ marginTop: "2rem" }}>
									<Button
										type="plus"
										size="small"
										style={{ marginRight: "1rem" }}
										onClick={() => this.props.onAddQuery(currentView, this.props.templates)}
									>
										<Icon type="plus" /> New Search
									</Button>
								</Row>
							) : null}
						</div>
					) : (
						<Row type="flex" align="middle" style={{ flexFlow: "column" }}>
							{this.props.workbooks.length > 0 ? (
								<Row>
									<h3>
										You Created Your First <span style={{ color: "#32b9ff" }}>DISCOVER</span>{" "}
										Workbook!
									</h3>
								</Row>
							) : (
								<Row>
									<h3>
										Welcome to <span style={{ color: "#32b9ff" }}>DISCOVER</span>
									</h3>
								</Row>
							)}
							{this.props.workbooks.length > 0 ? (
								<Row>Click your first workbook on the left side menu to get started!</Row>
							) : (
								<Row>Nothing to see here at the moment. Make a workbook to begin.</Row>
							)}
							<Row>
								<img src={this.props.noResults} style={{ height: 300 }} />
							</Row>
							{this.props.workbooks.length < 1 ? (
								<Row style={{ marginTop: "1rem" }}>
									<Button type="plus" size="large" onClick={() => this.props.onCreateWorkbook()}>
										<Icon type="plus" /> Create A New Workbook!
									</Button>
								</Row>
							) : null}
						</Row>
					)}
				</Col>
			</div>
		);
	}
}
export default QueryView;
