import React from "react";
import { PropTypes } from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/passwordActions";
import { Button, Row, Col, Form, Input, Icon, message, Spin } from "antd";
import { Image } from "react-bootstrap";
import logo from "../../images/ai4jobs-color-logo.png";

const FormItem = Form.Item;
export class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			form: { password: "", passwordConfirmation: "" },
			error: ""
		};
	}

	componentDidMount() {
		window.analytics.page("Reset Password Page");
	}

	onChangePassword = (event) => {
		const field = event.target.name;
		const form = this.state.form;
		form[field] = event.target.value;
		return this.setState({ form: form });
	};

	getValidationState() {
		const { password, passwordConfirmation } = this.state.form;
		if (password === passwordConfirmation && password.length > 0 && passwordConfirmation.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	resetPassword = (event) => {
		this.loadingMessage();
		event.preventDefault();
		if (this.getValidationState() === true) {
			this.props.actions.clearPasswordResetError();
			this.props.actions
				.resetPassword(this.state.form.password, this.props.match.params.guid)
				.then(() => this.successMessage())
				.catch(() => this.warningMessage());
		} else {
			this.props.actions.setPasswordResetError(this.state.error).then(() => this.warningMessage());
		}
	};

	redirectMessage = () => {
		message.loading("You are being redirected", 1.5).then(() => message.success("Redirect Complete", 1));
	};

	loadingMessage = () => {
		message.loading(`Checking passwords for errors.`);
	};

	warningMessage = () => {
		message.warning("Please try again.");
	};

	successMessage = () => {
		message.success("Your password has been reset!");
	};

	render() {
		const { password, passwordConfirmation } = this.state.form;

		if (this.props.passwordReset.message.length > 0) {
			this.redirectMessage();
			setTimeout(() => {
				this.props.history.push("/login");
			}, 1250);
		}

		return (
			<div className="reset-password-page" style={{ marginBottom: "5rem", minHeight: "85vh" }}>
				{this.props.passwordReset.message.length > 0 ? (
					<Row>
						<Row type="flex" justify="center">
							<Col span={4} style={{ marginBottom: "1rem" }}>
								<Image className="logo" src={logo} responsive />
							</Col>
						</Row>
						<Row type="flex" justify="center">
							<Col span={4} style={{ textAlign: "center", marginBottom: "1rem" }}>
								<h2 style={{ fontWeight: "bold" }}>Reset Complete</h2>
							</Col>
						</Row>
						<Row type="flex" justify="center">
							<Spin />{" "}
							<span style={{ fontWeight: "bold", marginLeft: "1rem" }}>
								You are being redirected to Login
							</span>
						</Row>
					</Row>
				) : (
					<Row>
						<Row type="flex" justify="center">
							<Col span={6} className="login-box">
								<Form onSubmit={(e) => this.resetPassword(e)}>
									<Row type="flex" justify="center">
										<Col span={24} style={{ marginBottom: "1rem" }}>
											<Image className="logo" src={logo} responsive />
										</Col>
									</Row>
									<Row type="flex" justify="center">
										<Col span={24} style={{ textAlign: "center", marginBottom: "1rem" }}>
											<h2 style={{ fontWeight: "bold" }}>Confirm Your Password</h2>
										</Col>
									</Row>
									<FormItem>
										<Input
											prefix={
												this.getValidationState() === true ? (
													<Icon type="lock" style={{ color: "rgba(0,128,0,.35)" }} />
												) : (
													<Icon type="unlock" style={{ color: "rgba(255,0,0,.35)" }} />
												)
											}
											type="password"
											placeholder="Password"
											name="password"
											onChange={this.onChangePassword}
										/>
									</FormItem>
									<FormItem>
										<Input
											prefix={
												this.getValidationState() === true ? (
													<Icon type="lock" style={{ color: "rgba(0,128,0,.35)" }} />
												) : (
													<Icon type="unlock" style={{ color: "rgba(255,0,0,.35)" }} />
												)
											}
											type="password"
											placeholder="Password Confirmation"
											name="passwordConfirmation"
											onChange={this.onChangePassword}
										/>
									</FormItem>
									<FormItem>
										{password === passwordConfirmation &&
										password.length > 0 &&
										passwordConfirmation.length > 0 ? (
											<Button
												className="button__style--login"
												style={{ width: "100%", marginBottom: "1rem" }}
												htmlType="submit"
											>
												Save New Password
											</Button>
										) : (
											<Button
												className="button__style--disabled"
												style={{ width: "100%", marginBottom: "1rem" }}
												htmlType="submit"
												disabled
											>
												Save New Password
											</Button>
										)}
									</FormItem>
								</Form>
							</Col>
						</Row>
					</Row>
				)}
			</div>
		);
	}
}

ResetPassword.propTypes = {
	actions: PropTypes.object.isRequired,
	passwordReset: PropTypes.object.isRequired,
	history: ReactRouterPropTypes.history.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			guid: PropTypes.string.isRequired
		})
	})
};

function mapStateToProps(state) {
	return {
		passwordReset: state.passwordReset
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

const ResetForm = Form.create()(ResetPassword);

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);
