import React, { Component } from "react";
import { PropTypes, func } from "prop-types";
import { QUERY_CATEGORIES } from "../constants/queryConstants";
import {
  Popconfirm,
  Icon,
  message,
  Select,
  Row,
  Col,
  Input,
  InputNumber,
  Switch,
  Tooltip,
  Slider
} from "antd";

const InputGroup = Input.Group;
const Option = Select.Option;

const text = "Would you like to remove these fields from your search?";

export class QueryCards extends Component {
  state = {
    career_length: false,
    career_length_op: "GTE",
    career_length_input: 1,
    career_length_text: "",
    career_count: false,
    career_count_op: "GTE",
    career_count_input: 1,
    career_count_text: "",
    mustHave: [],
    shouldHave: [],
    mustNotHave: []
  };

  componentWillMount() {
    this.CreateBanks();
  }

  CheckFilter(subFilter) {
    if (subFilter.mustHave[0]) {
      if (subFilter.mustHave[0].includes(":") === true) {
        return "range";
      } else {
        return "input";
      }
    } else {
      return "input";
    }
  }

  SelectValue(value, key, input) {
    if (value === "GTE") {
      return `Candidates with ${key
        .split("_")
        .join(" ")} Greater than or equal to ${input}`;
    } else if (value === "LTE") {
      return `Candidates with ${key
        .split("_")
        .join(" ")} Less than or equal to ${input}`;
    }
  }

  onSwitch(value, subFilter, filterIndex, subFilterIndex) {
    const row = this.props.filters[filterIndex].subCategories[subFilterIndex]
      .mustHave;
    const title = subFilter.key.split("_").join(" ");
    if (value === true) {
      if (row[0]) {
        if (row[0].includes(":") === false) {
          const newValues = [`GTE 1:LTE 5`];
          this.props.onUpdateFilter(
            filterIndex,
            subFilterIndex,
            "mustHave",
            newValues
          );
        }
      } else {
        const newValues = ["GTE 1:LTE 5"];

        this.props.onUpdateFilter(
          filterIndex,
          subFilterIndex,
          "mustHave",
          newValues
        );
      }
    } else if (value === false) {
      const newValues = ["GTE 1"];
      this.props.onUpdateFilter(
        filterIndex,
        subFilterIndex,
        "mustHave",
        newValues
      );
    }
  }

  componentWillReceiveProps() {}

  CheckDefaultSwitch(subFilter) {
    if (subFilter.mustHave.length === 1) {
      const check = subFilter.mustHave[0].includes(":");
      if (check === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onDefault(arr, type, key) {
    if (type === "range") {
      const arrNew = arr[0].split(":");
      const value1 = arrNew[0].split("GTE")[1];
      if (arrNew.length > 1) {
        const value2 = arrNew[1].split("LTE")[1];

        return [Number(value1), Number(value2)];
      }
      return [Number(value1), 5];
    } else {
      if (arr.length !== 0) {
        const update = arr[0].split(" ");
        const [select, input] = update;
        if (type === "input") {
          if (update.length === 1) {
            return Number(select);
          } else {
            return Number(input);
          }
        } else if (type === "select") {
          if (update.length === 1) {
            return "GTE";
          } else {
            return select;
          }
        }
      } else {
        if (type === "input") {
          return Number(1);
        } else if (type === "select") {
          return "Select";
        }
      }
    }
  }

  removeItems(key, index) {
    this.props.onRemoveFilter(key, index);
    message.success("Your search has been modified.");
  }

  SaveBank(type, term) {
    const arr = this.state[type];
    if (term !== "undefined") {
      if (this.state[type].indexOf(term) < 0 && typeof term === "string") {
        arr.push(term);
        this.setState({ [type]: arr });
      }
    }
  }

  CreateBanks() {
    this.props.filters.forEach((item, index) => {
      const exclude = ["location", "yearsExperience", "languages", "education"];

      if (exclude.indexOf(item.key) === -1) {
        item["subCategories"].forEach(category => {
          const types = ["mustHave", "shouldHave", "mustNotHave"];
          types.forEach(type => {
            if (category[type].length > 0) {
              category[type].forEach(term => {
                this.SaveBank(type, term);
              });
            }
          });
        });
      }
    });
  }

  CreateChildren(type) {
    const arr = [];
    this.state[type].map((term, index) => {
      arr.push(<Option key={term}>{term}</Option>);
    });
    return arr;
  }

  onDeselectTerm(value, option) {}

  render() {
    this.CreateBanks();
    let filters = this.props.filters
      .filter(searchFilter => {
        return this.props.cards.indexOf(searchFilter.key) > -1;
      })
      .map(filter => {
        let filterIndex = this.props.filters.indexOf(filter);
        const groupName = QUERY_CATEGORIES.find(category => {
          return category.key === filter.key;
        }).displayName;
        return (
          <div
            key={filter.key}
            className="filter-column"
            style={{ margin: "1rem" }}
          >
            <div
              className="filter-card"
              style={{
                marginBottom: "3rem",
                borderRadius: "10px",
                padding: "3rem",
                background: "white",
                boxShadow: "2px 6px 10px rgba(0, 0, 0, 0.15)"
              }}
            >
              <Popconfirm
                placement="topRight"
                title={text}
                onConfirm={() => this.removeItems(filter.key, filterIndex)}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  type="delete"
                  theme="outlined"
                  className="queryIcon__delete pull-right"
                />
              </Popconfirm>
              <h2 className="thin">
                {
                  QUERY_CATEGORIES.find(category => {
                    return category.key === filter.key;
                  }).displayName
                }
              </h2>
              <Row className="subFilter-options">
                {filter.subCategories.map((subFilter, subFilterIndex) => {
                  return (
                    <Col
                      key={subFilter.key}
                      span={12}
                      style={{ marginBottom: "2rem" }}
                    >
                      {groupName !== "Years Experience" ? (
                        <Col>
                          <Row span={24}>
                            <Col span={12}>
                              <h4>
                                {
                                  QUERY_CATEGORIES.find(category => {
                                    return category.key === filter.key;
                                  }).subCategoryDisplayName[subFilter.key]
                                }
                              </h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={22}>
                              <InputGroup style={{ marginBottom: "1rem" }}>
                                <label
                                  className="beforeInput__required"
                                  style={{
                                    width: "20%"
                                  }}
                                >
                                  Require
                                </label>

                                <Select
                                  id="querySelect"
                                  allowClear={true}
                                  mode="tags"
                                  maxTagCount={5}
                                  style={{ width: "80%" }}
                                  onDeselect={(value, option) =>
                                    this.onDeselectTerm(value, option)
                                  }
                                  value={subFilter.mustHave}
                                  key="mustHave"
                                  placeholder="Enter filter criteria..."
                                  onChange={newValues => {
                                    newValues.forEach((newValue, i) => {
                                      if (typeof newValue === "object") {
                                        newValues[i] = newValue.mustHave;
                                      }
                                      this.SaveBank("mustHave", newValue);
                                    });
                                    this.props.onUpdateFilter(
                                      filterIndex,
                                      subFilterIndex,
                                      "mustHave",
                                      newValues
                                    );
                                  }}
                                >
                                  {this.CreateChildren("mustHave")}
                                </Select>
                                <Tooltip
                                  placement="topLeft"
                                  title={
                                    "Require terms will return candidates who match these terms."
                                  }
                                >
                                  <Icon
                                    className="query__icon"
                                    type="info-circle"
                                    theme="outlined"
                                  />
                                </Tooltip>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={22}>
                              <InputGroup style={{ marginBottom: "1rem" }}>
                                <label
                                  className="beforeInput__required"
                                  style={{
                                    width: "20%"
                                  }}
                                >
                                  Include
                                </label>
                                <Select
                                  id="querySelect"
                                  allowClear={true}
                                  mode="tags"
                                  maxTagCount={5}
                                  style={{ width: "80%" }}
                                  value={subFilter.shouldHave}
                                  key="shouldHave"
                                  placeholder="Enter filter criteria..."
                                  onChange={newValues => {
                                    newValues.forEach((newValue, i) => {
                                      if (typeof newValue === "object") {
                                        newValues[i] = newValue.shouldHave;
                                      }
                                      this.SaveBank("shouldHave", newValue);
                                    });
                                    this.props.onUpdateFilter(
                                      filterIndex,
                                      subFilterIndex,
                                      "shouldHave",
                                      newValues
                                    );
                                  }}
                                >
                                  {this.CreateChildren("shouldHave")}
                                </Select>
                                <Tooltip
                                  placement="topLeft"
                                  title={
                                    "Included terms will match candidates who have these terms if they match all required terms as well."
                                  }
                                >
                                  <Icon
                                    className="query__icon"
                                    type="info-circle"
                                    theme="outlined"
                                  />
                                </Tooltip>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={22}>
                              <InputGroup style={{ marginBottom: "1rem" }}>
                                <label
                                  className="beforeInput__required"
                                  style={{
                                    width: "20%"
                                  }}
                                >
                                  Exclude
                                </label>

                                <Select
                                  id="querySelect"
                                  allowClear={true}
                                  mode="tags"
                                  maxTagCount={5}
                                  style={{ width: "80%" }}
                                  value={subFilter.mustNotHave}
                                  key="mustNotHave"
                                  placeholder="Enter filter criteria..."
                                  onChange={newValues => {
                                    newValues.forEach((newValue, i) => {
                                      if (typeof newValue === "object") {
                                        newValues[i] = newValue.mustNotHave;
                                      }
                                      this.SaveBank("mustNotHave", newValue);
                                    });
                                    this.props.onUpdateFilter(
                                      filterIndex,
                                      subFilterIndex,
                                      "mustNotHave",
                                      newValues
                                    );
                                  }}
                                >
                                  {this.CreateChildren("mustNotHave")}
                                </Select>
                                <Tooltip
                                  placement="topLeft"
                                  title={
                                    "Excluded terms will prevent candidates that have these specific terms."
                                  }
                                >
                                  <Icon
                                    className="query__icon"
                                    type="info-circle"
                                    theme="outlined"
                                  />
                                </Tooltip>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col>
                          <Row span={24}>
                            <Col span={18}>
                              <h4>
                                {
                                  QUERY_CATEGORIES.find(category => {
                                    return category.key === filter.key;
                                  }).subCategoryDisplayName[subFilter.key]
                                }
                              </h4>
                            </Col>
                          </Row>
                          <Row>
                            <label style={{ marginRight: "1rem" }}>
                              Ranges:
                            </label>
                            <Switch
                              checked={this.CheckDefaultSwitch(subFilter)}
                              size="small"
                              onChange={value =>
                                this.setState(
                                  {
                                    [subFilter.key + "_" + "text"]: ""
                                  },
                                  function() {
                                    this.onSwitch(
                                      value,
                                      subFilter,
                                      filterIndex,
                                      subFilterIndex
                                    );
                                  }
                                )
                              }
                            />
                          </Row>
                          <Row span={24}>
                            {this.CheckFilter(subFilter) === "input" ? (
                              <Col span={20}>
                                <Row>
                                  <InputGroup
                                    style={{ marginBottom: "1rem" }}
                                    compact
                                  >
                                    <InputNumber
                                      min={0}
                                      text="left"
                                      defaultValue={this.onDefault(
                                        subFilter.mustHave,
                                        "input",
                                        subFilter.key
                                      )}
                                      onChange={value => {
                                        this.setState(
                                          {
                                            [subFilter.key +
                                            "_" +
                                            "input"]: value,
                                            [subFilter.key +
                                            "_" +
                                            "text"]: `Candidates with ${subFilter.key
                                              .split("_")
                                              .join(" ")} ${
                                              this.state[
                                                subFilter.key + "_" + "op"
                                              ] === "GTE"
                                                ? "greater than or equal to"
                                                : "Less than or equal to"
                                            } ${value}`
                                          },
                                          function() {
                                            const operator = this.state[
                                              subFilter.key + "_" + "op"
                                            ];
                                            const inputValue = this.state[
                                              subFilter.key + "_" + "input"
                                            ];
                                            const passData = [
                                              `${operator} ${inputValue}`
                                            ];
                                            this.props.onUpdateFilter(
                                              filterIndex,
                                              subFilterIndex,
                                              "mustHave",
                                              passData
                                            );
                                          }
                                        );
                                      }}
                                    />
                                    <Select
                                      defaultValue={this.onDefault(
                                        subFilter.mustHave,
                                        "select"
                                      )}
                                      onSelect={value => {
                                        this.setState(
                                          {
                                            [subFilter.key + "_" + "op"]: value
                                          },
                                          function() {
                                            const operator = this.state[
                                              subFilter.key + "_" + "op"
                                            ];
                                            const inputValue = this.state[
                                              subFilter.key + "_" + "input"
                                            ];
                                            const passData = [
                                              `${operator} ${inputValue}`
                                            ];

                                            this.setState({
                                              [subFilter.key +
                                              "_" +
                                              "text"]: this.SelectValue(
                                                value,
                                                subFilter.key,
                                                this.state[
                                                  subFilter.key + "_" + "input"
                                                ]
                                              )
                                            });

                                            this.props.onUpdateFilter(
                                              filterIndex,
                                              subFilterIndex,
                                              "mustHave",
                                              passData
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      <Option key={subFilter.key} value="GTE">
                                        or more
                                      </Option>
                                      <Option value="LTE">or less</Option>
                                    </Select>
                                  </InputGroup>
                                </Row>
                                <Row>
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontSize: "1.10rem"
                                    }}
                                  >
                                    {this.state[subFilter.key + "_" + "text"]}
                                  </span>
                                </Row>
                              </Col>
                            ) : (
                              <Row>
                                <Col span={20}>
                                  <Slider
                                    range
                                    dots={true}
                                    min={1}
                                    max={20}
                                    defaultValue={this.onDefault(
                                      subFilter.mustHave,
                                      "range"
                                    )}
                                    onChange={value => {
                                      const field_label = QUERY_CATEGORIES.find(
                                        category => {
                                          return category.key === filter.key;
                                        }
                                      ).subCategoryDisplayName[subFilter.key];

                                      this.setState(
                                        {
                                          [subFilter.key +
                                          "_" +
                                          "text"]: `Candidates with a ${field_label} between ${
                                            value[0]
                                          } and ${value[1]}`
                                        },
                                        function() {
                                          const passData = [
                                            `GTE ${value[0]}:LTE ${value[1]}`
                                          ];
                                          this.props.onUpdateFilter(
                                            filterIndex,
                                            subFilterIndex,
                                            "mustHave",
                                            passData
                                          );
                                        }
                                      );
                                    }}
                                  />
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      fontSize: "1.10rem"
                                    }}
                                  >
                                    {this.state[subFilter.key + "_" + "text"]}
                                  </span>
                                </Col>
                              </Row>
                            )}
                          </Row>
                        </Col>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        );
      });

    return <div className="filters">{filters}</div>;
  }
}

QueryCards.propTypes = {
  cards: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  onRemoveFilter: func.isRequired,
  onUpdateFilter: func.isRequired
};

export default QueryCards;
