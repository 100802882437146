const BASE_URL = process.env.API_URL;
const API = BASE_URL + "/api/v1";
const ADMIN = BASE_URL + "/admin/api/v1";
export const AUTHENTICATION = BASE_URL + "/oauth/token";
export const WORKBOOK_DETAILS = API + "/workbook?details=true";
export const WORKBOOK = API + "/workbook";
export const PASSWORD_RESET_EMAIL = BASE_URL + "/password/reset ";
export const RESET_PASSWORD = BASE_URL + "/password/change";

export function query(workbookId) {
	return `${API}/workbook/${workbookId}/query`;
}

export function getPreview(workbookId, queryId) {
	return `${API}/preview/${workbookId}/query/${queryId}`;
}

export function getSearch(workbookId, queryId) {
	return `${API}/search/${workbookId}/query/${queryId}`;
}

export function enrichSearch() {
	return `${API}/enrich`;
}

export function exportSearch() {
	return `${API}/export`;
}

export function copyQuery(workbookId, queryId) {
	return `${API}/workbook/${workbookId}/query/${queryId}/copy`;
}

export function getUser() {
	return `${API}/user/me`;
}

export function moveQuery(queryId, workbookId, type) {
	return `${API}/workbook/${workbookId}/query/${queryId}/${type}`;
}

export function getTeam() {
	return `${API}/user/team`;
}

export function shareQuery(workbookId, queryId) {
	return `${API}/workbook/${workbookId}/query/${queryId}/share`;
}

export function shareWorkbook(workbookId) {
	return `${API}/workbook/${workbookId}/share`;
}

export function getUsageByUser() {
	return `${BASE_URL}/admin/api/v1/user/usage`;
}

export function getUserMeta() {
	return `${API}/user/meta`;
}

export function getUserPreferences() {
	return `${API}/user/preferences`;
}

export function getJobReq() {
	return `${API}/jobreq`;
}

export function getGreenhouse(key) {
	return `${ADMIN}/reveal/client`;
}

export function getGHClients() {
	return `${ADMIN}/reveal/client`;
}

export function getGHClientByKey(id) {
	return `${ADMIN}/reveal/client/${id}`;
}

export function updateGHClient(key) {
	return `${ADMIN}/reveal/client/${key}`;
}

export function AllGHClientOptions(key) {
	return `${ADMIN}/reveal/client/${key}/option`;
}

export function GHClientOption(key, optionName) {
	return `${ADMIN}/reveal/client/${key}/option/${optionName}`;
}

export function startValueDemo(key) {
	return `${BASE_URL}/reveal/api/v1/preview/start/atskey/${key}`;
}

export function trackValueDemo(key, tracking) {
	return `${BASE_URL}/reveal/api/v1/preview/status/client/${key}/tracking/${tracking}`;
}

export function fullEstimate(key) {
	return `${BASE_URL}/reveal/api/v1/estimate/atskey/${key}`;
}

export function jobEstimate(key, id) {
	return `${BASE_URL}/reveal/api/v1/estimate/atskey/${key}/job/${id}`;
}
