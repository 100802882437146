import { LOGIN_SUCCESS, LOGOUT, LOGIN_ATTEMPT } from "../constants/actionTypes";
import initialState from "./initialState";

export default function sessionReducer(state = initialState.session, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        auth: !!localStorage.accessToken,
        attempt: action.payload
      });

    case LOGOUT:
      return Object.assign({}, state, {
        auth: !!localStorage.accessToken,
        attempt: action.payload
      });

    case LOGIN_ATTEMPT:
      return Object.assign({}, state, { attempt: action.payload });

    default:
      return state;
  }
}
