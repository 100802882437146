import { TEST_ACTION } from "../constants/actionTypes";
import initialState from "./initialState";

export default function queryTesterReducer(state = initialState.queryTester, action) {
	switch (action.type) {
		case TEST_ACTION:
			return Object.assign({}, state, action.payload);

		default:
			return state;
	}
}
